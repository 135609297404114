import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import {TextField} from '@material-ui/core';
import _ from '@lodash';
import {
    OwpTreeGrid,
    OwpButton,
    OwpDialog2,
} from '@wrapper';
import * as TGEvent from "@TGEvent/index";
import {Button} from '@material-ui/core';
import { onGridLoad } from "@owp/common";

class TextFieldFormsy extends Component {

    constructor(props) {
        super(props);            
        this.state = {
            nowValue : ''
        };
    }

    changeValue = (event) => {
        this.props.setValue(event.currentTarget.value);
        if ( this.props.onChange )
        {
            this.props.onChange(event);
        }

        this.setState({
            nowValue : event.currentTarget.value
        })
    };

    popOpen = () =>{

        this.owpDialog1.handleClickOpen()

        if(this.state.nowValue !== ''){
            setTimeout(()=>{
                this.search()
            }, 1000)
        }

    }

    search = () =>{
        TGEvent.OWPonGridLoad(
            "owp_common_product", 
        
            TGEvent.OWPTGDataList_Origin(
                "owp_common_product" 
                , "listOwpCmProdRuleProdSearch" 
                ,  { "OWP_CM_PROD_RULE.PROD_NO" : document.getElementById('equip_nm').value }
            ),
            document.getElementById('equip_nm').value = this.props.getValue() || ''
        );
    }

    click = () =>{
        this.props.setValue('');

        this.state = {
            nowValue : this.props.getValue() || ''
        };
    }


    render()
    {

        const importedProps = _.pick(this.props, [
            'autoComplete',
            'autoFocus',
            'children',
            'className',
            'defaultValue',
            'disabled',
            'FormHelperTextProps',
            'fullWidth',
            'id',
            'InputLabelProps',
            'inputProps',
            'InputProps',
            'inputRef',
            'label',
            'multiline',
            'name',
            'onBlur',
            'onChange',
            'onFocus',
            'onClick',
            'placeholder',
            'required',
            'rows',
            'rowsMax',
            'select',
            'SelectProps',
            'type',
            'variant',
            'style',
            'readOnly'
        ]);

        const errorMessage = this.props.getErrorMessage();
        const value = this.props.getValue() || '';

        return (
            <div style={{float:'left'}}>
                <TextField
                    {...importedProps}
                    onChange={this.changeValue}
                    value={value}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                    InputLabelProps={{
                        className: "HKC-TextField",
                        shrink: true,
                    }}  
                    onClick={this.click}
                />
                <div
                    style={{
                        cursor : 'pointer',
                        border : '1px solid white',
                        color : 'white',
                        display:'flex',
                        justifyContent: 'center',
                        width:'17px',
                        borderRadius:'6px',
                        float:'right',
                        marginTop:'32px',
                        marginLeft:'-7px'

                    }}
                    onClick={this.popOpen}
                >
                    ^
                </div>

                <OwpDialog2
                    title = "품명 검색"
                    disableBackdropClick={false}
                    dialogActions={
                        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                            <React.Fragment>                                     
                                <OwpButton onClick={()=>{ 
                                    const G = TGEvent.getGridByID("owp_common_product");
                                    const _rows = G.GetSelRows();

                                    G.GetSelRows().map((row,i) => {  
                                        this.props.setValue(row["OWP_CM_PROD_RULE.PROD_NM"] + ':' + row["OWP_CM_PROD_RULE.PROD_SEQ"] );
                                    });

                                    this.owpDialog1.handleClose();
                                    
                                }} color="secondary" variant="contained">
                                    확인
                                </OwpButton>

                                <Button color="default" variant="outlined" 
                                        onClick={()=>{ this.owpDialog1.handleClose(); }} 
                                    style={{color:'black', border:'1px solid rgba(0, 0, 0, 0.23)', borderRadius:'4px', padding:'4px 8px', minWidth:'64px', margin:'8px', fontSize:'1.3rem'}}
                                > {'닫기'}
                                </Button>
                            </React.Fragment>
                        </div>
                    } innerRef={ref => this.owpDialog1 = ref}
                    width="600px"
                    height="600px"
                >
                        
                    <React.Fragment>
                        <div style={{height: "540px"}}>
                            <div style={{display: "flex", justifyContent: "center", marginBottom: '20px'}}>                                      
                                <input type="text"
                                    name="equip_nm"
                                    id="equip_nm"
                                    className="table-bordered"      
                                    style={{height: '30px', marginTop: '9px'}}    
                                    onKeyPress={(e)=>{ 
                                        if(e.key == 'Enter')
                                        
                                        TGEvent.OWPTGDataList_Origin(
                                            "owp_common_product" 
                                            , "listOwpCmProdRuleProdSearch" 
                                            ,  { "OWP_CM_PROD_RULE.PROD_NO" : document.getElementById('equip_nm').value }
                                        );
                                    }}                                        
                                />
                                <OwpButton onClick={()=>{
                                    TGEvent.OWPTGDataList_Origin(
                                        "owp_common_product" 
                                        , "listOwpCmProdRuleProdSearch" 
                                        ,  { "OWP_CM_PROD_RULE.PROD_NO" : document.getElementById('equip_nm').value }
                                    );

                                }} variant="contained" color="secondary">
                                    검색
                                </OwpButton>
                            </div>
                            <OwpTreeGrid
                                id={"owp_common_product"}
                                LayoutUrl={"/assets/data/owp_common_productDef.xml"}
                                callFunction={(type, callbackfunc) => {
                                        
                                }}
                            />
                        </div>
                    </React.Fragment>
                </OwpDialog2>
            </div>
        );
    }
}

export default withFormsy(TextFieldFormsy);
