import {OwpMessage,OwpExcel, OwpSession} from "../@wrapper";
import {mutate, query} from "../@owp/api";
import * as TGEvent from "@TGEvent/index"; 
import _ from '@lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {font} from '@PopCommon/SpoqaHanSansRegular-normal';
import XLSX from 'xlsx';
import { OwpStorage } from '@owp/common';
/**
 * DESCRIPTION  : 공정관리쪽 공통 로직 
 * DATE         : 2019.12.24 
 * AUTHOR       : jinseon.lee 
 */
const getAccessToken = function () {
    return window.localStorage.getItem('jwt_access_token');
};

// 숫자 세자리마다 콤마 찍어줌 
export const setNumberFormat = function(lessNum){ 
    let num = 0;
    if(lessNum != undefined && lessNum != null && lessNum != ''){
        num = lessNum;
    }
    let isMinus = false;
    if(String(lessNum).indexOf("-") > -1){
        isMinus= true;
    }

    let num_arr = String(num).split('.');
    if(num_arr.length > 1){
        num =  String(num_arr[0]).replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",").concat('.').concat(num_arr[1].replace(/[^0-9]/g, ''));
    }else{
        num =  String(num).replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    if(isMinus){
       return ("-"+num);
    }else{
        return num;
    }

}

// 숫자 세자리마다 콤마 찍어줌 기본값이 없도록 처리 
export const setNumberFormat2 = function(lessNum){ 
    let num = lessNum;
    if(lessNum != undefined && lessNum != null && lessNum != ''){
        num = lessNum;
    }
    let isMinus = false;
    if(String(lessNum).indexOf("-") > -1){
        isMinus= true;
    }

    let num_arr = String(num).split('.');
    if(num_arr.length > 1){
        num =  String(num_arr[0]).replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",").concat('.').concat(num_arr[1].replace(/[^0-9]/g, ''));
    }else{
        num =  String(num).replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    if(isMinus){
       return ("-"+num);
    }else{
        return num;
    }

}


/**
 * 숫자의 천단위 , 를 없애줌 
 * 소수점은 안없앰 
 */
export const setOnlyNum = function(commaNum) {
    let num = commaNum;
    let num_arr = String(num).split('.');
    if(num_arr.length > 1){
        num =  String(num_arr[0]).replace(/[^0-9]/g, '').concat('.').concat(num_arr[1].replace(/[^0-9]/g, ''));
    }else{
        num =  String(num).replace(/[^0-9]/g, '')
    }
    return num;
    }




// 분을 일 시:분 으로 보기 
export const setDayHourMinFormat = function(min){
    let num = 0;
    if(min != undefined && min != null && min != ''){
        num = min;
    }


    let day = parseInt(num / 1440);
    let hour = parseInt(( num % 1440 ) / 60) ;
    let m = parseInt(( num % 1440 ) % 60) ;

    let str = '';

    if(day > 0){
        str += day + ' ';
    }

    if(day > 0 || hour > 0){
        if(hour == 0){
            str +=  '00:';
        }else{
           if(hour < 10){
            str +=  ('0'+hour+':');
           }else{
            str +=  ( hour+':');  
           } 
        }
    }
    
    if(m < 10){
        str +=  ('0'+m);
    }else{
     str +=  ( m);  
    } 
    return str;
}

// 비가동 4시간 지남 
// 작업목록 테이블에 저장 
export const setOverTime = function(WORKASSIGN_SEQ, WORKASSIGN_DETL_SEQ, WUSERSEQ  , callbackfunc){

    const data = { 
        cudtype : "create",
        "OWP_WO_WORKMNGT.WORKASSIGN_SEQ"         : String(WORKASSIGN_SEQ) ,
        "OWP_WO_WORKMNGT.WORKASSIGN_DETL_SEQ"    : String(WORKASSIGN_DETL_SEQ) ,
        "OWP_WO_WORKMNGT.WUSERSEQ"               : String(OwpSession('USERSEQ'))
     }

    mutate({
        url: '/createD021026OwpWoWorkmngt' ,data
    }).then(res => {
        console.log("res ::::", res);
        callbackfunc(res);
   }).catch(error => console.error(error)); 

}

// 비가동 조회
/**  
 * @param obj = {
 *  WORKASSIGN_DETL_SEQ 
 * , PROC_TCD 
 * , SFTYPE_TCD 
 * , EMPLOYEE_USERSEQ
 * } 
*/  
export const getOwpSopnFaileTime = function(obj , callbackfunc ){

    query({
        url: '/getOwpSopnFaileTime',
        params: {jsondata : "{\"OWP_WO_SOPNFALE.WORKASSIGN_DETL_SEQ\" : \"" + obj.WORKASSIGN_DETL_SEQ 
                            + "\", \"OWP_WO_SOPNFALE.PROC_TCD\" : \"" + obj.PROC_TCD 
                            + "\", \"OWP_WO_SOPNFALE.SFTYPE_TCD\" : \"" + obj.SFTYPE_TCD  
                            + "\", \"OWP_WO_SOPNFALE.EMPLOYEE_USERSEQ\" : \"" + obj.EMPLOYEE_USERSEQ  
                            + "\"}"}
    }).then(res => {
        console.log("getOwpSopnFaileTime >>>> ", res);
        callbackfunc(res);
    }).catch(error => {
        console.log('TCL: error', error);
    });

}

/**  
 *  비가동 저장 
*/  
export const setOwpViewSopnFail = function async( obj, callbackfunc ){

    let data = {}; 
    let url = '';
    
    if(obj.type == 'start'){
        data = {
            cudtype: "create",
            "OWP_WO_SOPNFALE.MNG_SEQ"                   : String(obj.MNG_SEQ) ,
            "OWP_WO_SOPNFALE.WORKASSIGN_SEQ"            : String(obj.WORKASSIGN_SEQ) ,
            "OWP_WO_SOPNFALE.WORKASSIGN_DETL_SEQ"       : String(obj.WORKASSIGN_DETL_SEQ) ,
            "OWP_WO_SOPNFALE.WORKORDER_SEQ"             : String(obj.WORKORDER_SEQ) ,
            "OWP_WO_SOPNFALE.PROC_TCD"                  : String(obj.PROC_TCD) ,
            "OWP_WO_SOPNFALE.SFTYPE_TCD"                : String(obj.SFTYPE_TCD) ,
            "OWP_WO_SOPNFALE.EMPLOYEE_USERSEQ"          : String(obj.EMPLOYEE_USERSEQ) ,
            "OWP_WO_SOPNFALE.FLAG"                      : "Y",
            "OWP_WO_SOPNFALE.STATUS"                    : "Create",
            "OWP_WO_SOPNFALE.WUSERSEQ"                  : String(OwpSession('USERSEQ')) ,
            "OWP_WO_WORKMNGT": [{
                "OWP_WO_WORKMNGT.STATUS"                : "CREATE",
                "OWP_WO_WORKMNGT.WORKASSIGN_SEQ"        : String(obj.WORKASSIGN_SEQ) ,
                "OWP_WO_WORKMNGT.WORKASSIGN_DETL_SEQ"   : String(obj.WORKASSIGN_DETL_SEQ) ,
                "OWP_WO_WORKMNGT.WORKORDER_SEQ"	        : String(obj.WORKORDER_SEQ) ,
                "OWP_WO_WORKMNGT.WORK_CD"	            : String(obj.WORK_CD) ,
                "OWP_WO_WORKMNGT.REMARK"		        : String(obj.REMARK == undefined ? "추후입력": obj.REMARK ) ,
                "OWP_WO_WORKMNGT.FLAG"		            : "Y",
                "OWP_WO_WORKMNGT.WUSERSEQ"	            : String(OwpSession('USERSEQ')) ,
                "OWP_WO_WORKMNGT.WORK_USERSEQ"          : String(obj.EMPLOYEE_USERSEQ )  // 2019.12.31 jinseon.lee  
            }]
            ,LOG_PAGEID : 'PopComon'
            ,LOG_WUSERSEQ : OwpSession("USERSEQ") || '1'
            ,LOG_USERIP : OwpStorage.getUserIp()
            ,LOG_REMARK : 'POP 저장'
        }
        url = "/createOwpWoSopnfale"; 
    }else{
        data = {
            cudtype: "update",
            "OWP_WO_SOPNFALE.SOPNFALE_SEQ"              : String(obj.SOPNFALE_SEQ) ,
            "OWP_WO_SOPNFALE.WORKASSIGN_DETL_SEQ"       : String(obj.WORKASSIGN_DETL_SEQ)  ,
            "OWP_WO_SOPNFALE.WORKASSIGN_SEQ"            : String(obj.WORKASSIGN_SEQ) ,
            "OWP_WO_SOPNFALE.WORKORDER_SEQ"             : String(obj.WORKORDER_SEQ) ,
            "OWP_WO_SOPNFALE.PROC_TCD"                  : String(obj.PROC_TCD) ,
            "OWP_WO_SOPNFALE.SFTYPE_TCD"                : String(obj.SFTYPE_TCD) ,
            "OWP_WO_SOPNFALE.EMPLOYEE_USERSEQ"          : String(obj.EMPLOYEE_USERSEQ) ,
            "OWP_WO_SOPNFALE.FLAG"                      : "Y",
            "OWP_WO_SOPNFALE.WUSERSEQ"                  : String(OwpSession('USERSEQ')) ,
            "OWP_WO_SOPNFALE.STATUS"                    : "Update",
            "OWP_WO_WORKMNGT": [{
                "OWP_WO_WORKMNGT.STATUS"                : "UPDATE",
                "OWP_WO_WORKMNGT.WORKMNGT_SEQ"          : String(obj.WORKMNGT_SEQ),
                "OWP_WO_WORKMNGT.WORKASSIGN_SEQ"        : String(obj.WORKASSIGN_SEQ) ,
                "OWP_WO_WORKMNGT.WORKASSIGN_DETL_SEQ"   : String(obj.WORKASSIGN_DETL_SEQ) ,
                "OWP_WO_WORKMNGT.WORKORDER_SEQ"	        : String(obj.WORKORDER_SEQ) ,
                "OWP_WO_WORKMNGT.WORK_CD"	            : String(obj.WORK_CD),
                "OWP_WO_WORKMNGT.REMARK"		        : String(obj.REMARK == undefined ? "추후입력": obj.REMARK ) ,
                "OWP_WO_WORKMNGT.FLAG"		            : "Y",
                "OWP_WO_WORKMNGT.WUSERSEQ"	            : String(OwpSession('USERSEQ')) ,
                "OWP_WO_WORKMNGT.WORK_USERSEQ"          : String(obj.WORK_USERSEQ)  
            }]
            ,LOG_PAGEID : 'PopComon'
            ,LOG_WUSERSEQ : OwpSession("USERSEQ") || '1'
            ,LOG_USERIP : OwpStorage.getUserIp()
            ,LOG_REMARK : 'POP 저장'
        }
        url = "/updateOwpWoSopnfale";
    }


    console.log(" 저장전 테이터 비교 >>>>>>>>>>>> " , data );


    mutate({
        url: url,
        data
    }).then((res) => {
        if(res['resultCode'] === "STATUS_1"){
            OwpMessage({
                message: '정상적으로 처리되었습니다.',
                variant: 'info'
            });
        
            callbackfunc(res);
        }
    }).catch((error) => {
        console.error(error);
    })


}

/* 작업자변경 */
export const setWorkerChange = function(obj , newWorkUserSeq, callbackfunc ){

    const data = { 
        cudtype : "update",
        "OWP_PN_WORKASSIG_D.WORKASSIGN_SEQ"         : String(obj.WORKASSIGN_SEQ) ,
        "OWP_PN_WORKASSIG_D.WORKASSIGN_DETL_SEQ"    : String(obj.WORKASSIGN_DETL_SEQ) ,
        "OWP_PN_WORKASSIG_D.EMPLOYEE_USERSEQ"       : String(newWorkUserSeq), 
        "OWP_PN_WORKASSIG_D.WUSERSEQ"               : String(OwpSession('USERSEQ')) ,
        "OWP_WO_WORKMNGT": [{
            "OWP_WO_WORKMNGT.STATUS"                : "CREATE",
            "OWP_WO_WORKMNGT.WORKASSIGN_SEQ"        : String(obj.WORKASSIGN_SEQ) ,
            "OWP_WO_WORKMNGT.WORKASSIGN_DETL_SEQ"   : String(obj.WORKASSIGN_DETL_SEQ) ,
            "OWP_WO_WORKMNGT.WORKORDER_SEQ"	        : String(obj.WORKORDER_SEQ) ,
            "OWP_WO_WORKMNGT.WORK_CD"	            : String('D021027') ,
            "OWP_WO_WORKMNGT.REMARK"		        : String('작업자변경') ,
            "OWP_WO_WORKMNGT.FLAG"		            : "Y",
            "OWP_WO_WORKMNGT.WUSERSEQ"	            : String(OwpSession('USERSEQ')) ,
            "OWP_WO_WORKMNGT.WORK_USERSEQ"          : String(newWorkUserSeq)  // 2019.12.31 jinseon.lee  
        }]
        ,LOG_PAGEID : 'PopComon'
        ,LOG_WUSERSEQ : OwpSession("USERSEQ") || '1'
        ,LOG_USERIP : OwpStorage.getUserIp()
        ,LOG_REMARK : '작업자변경 수정'
     }

    mutate({
        url: '/updateOwpPnWorkassigDPop' , data
    }).then(res => {
        console.log("res ::::", res);
        callbackfunc(res);
   }).catch(error => console.error(error)); 

}

/** 원소재 데이터 목록 조회 */ 
export const listMatrialData = function(grid_id , seq){ 
    if(seq != '' && seq != undefined){
        TGEvent.OWPTGDataList(grid_id, 'listOwpCmProdmatr2', 
            {'OWP_CM_PRODMATR.WORKASSIGN_DETL_SEQ' :  String(seq) } , '원소재 데이터 목록 조회'           
        ); 
    }
}

/** 원소재 데이터 목록 조회 2*/ 
export const listMatrialData2 = function(grid_id , seq){ 
    if(seq != '' && seq != undefined){
        TGEvent.OWPTGDataList(grid_id, 'listOwpCmProdmatrRework', 
            {'OWP_CM_PRODMATR.WORKASSIGN_DETL_SEQ' :  String(seq) } , '원소재 데이터 목록 조회'              
        ); 
    }
}


/* 인서트LOT번호 저장 */
export const setInsertLotNo = function(obj , loginUser , callbackfunc ){

    const data = { 
        cudtype : "update",
        "OWP_PN_WORKASSIG_D.WORKASSIGN_SEQ"         : String(obj.WORKASSIGN_SEQ) ,
        "OWP_PN_WORKASSIG_D.WORKASSIGN_DETL_SEQ"    : String(obj.WORKASSIGN_DETL_SEQ) ,
        "OWP_PN_WORKASSIG_D.PROC_LOT_NO"            : String(obj.PROC_LOT_NO), 
        "OWP_PN_WORKASSIG_D.WUSERSEQ"               : String(OwpSession('USERSEQ')) ,
        "OWP_WO_WORKMNGT" : [{
            "OWP_WO_WORKMNGT.STATUS"                : "CREATE",
            "OWP_WO_WORKMNGT.WORKASSIGN_SEQ"        : String(obj.WORKASSIGN_SEQ) ,
            "OWP_WO_WORKMNGT.WORKASSIGN_DETL_SEQ"   : String(obj.WORKASSIGN_DETL_SEQ) ,
            "OWP_WO_WORKMNGT.WORKORDER_SEQ"	        : String(obj.WORKORDER_SEQ) ,
            "OWP_WO_WORKMNGT.WORK_CD"	            : String('D021029') ,
            "OWP_WO_WORKMNGT.REMARK"		        : String('LotNo변경') ,
            "OWP_WO_WORKMNGT.FLAG"		            : "Y",
            "OWP_WO_WORKMNGT.WUSERSEQ"	            : String(OwpSession('USERSEQ')) ,
            "OWP_WO_WORKMNGT.WORK_USERSEQ"          : String(obj.EMPLOYEE_USERSEQ)  // 2019.12.31 jinseon.lee  
        }]
        ,LOG_PAGEID : 'PopComon'
        ,LOG_WUSERSEQ : OwpSession("USERSEQ") || '1'
        ,LOG_USERIP : OwpStorage.getUserIp()
        ,LOG_REMARK : '인서트LOT번호 수정'
     }

    mutate({
        url: '/updateOwpPnWorkassigDPop' , data
    }).then(res => {
        console.log("res ::::", res);
        callbackfunc(res);
   }).catch(error => console.error(error)); 

}

/* 인서트LOT번호 저장 */
export const setInsertLotNo2 = function(obj , loginUser , callbackfunc ){

    const data = { 
        cudtype : "update",
        "OWP_PN_WORKASSIG_D.WORKASSIGN_SEQ"         : String(obj.WORKASSIGN_SEQ) ,
        "OWP_PN_WORKASSIG_D.WORKASSIGN_DETL_SEQ"    : String(obj.WORKASSIGN_DETL_SEQ) ,
        "OWP_PN_WORKASSIG_D.PROC_LOT_NO"            : String(obj.PROC_LOT_NO), 
        "OWP_PN_WORKASSIG_D.PROC_LOT_NO2"           : String(obj.PROC_LOT_NO2), 
        "OWP_PN_WORKASSIG_D.WUSERSEQ"               : String(OwpSession('USERSEQ')) ,
        "OWP_WO_WORKMNGT" : [{
            "OWP_WO_WORKMNGT.STATUS"                : "CREATE",
            "OWP_WO_WORKMNGT.WORKASSIGN_SEQ"        : String(obj.WORKASSIGN_SEQ) ,
            "OWP_WO_WORKMNGT.WORKASSIGN_DETL_SEQ"   : String(obj.WORKASSIGN_DETL_SEQ) ,
            "OWP_WO_WORKMNGT.WORKORDER_SEQ"	        : String(obj.WORKORDER_SEQ) ,
            "OWP_WO_WORKMNGT.WORK_CD"	            : String('D021029') ,
            "OWP_WO_WORKMNGT.REMARK"		        : String('LotNo변경') ,
            "OWP_WO_WORKMNGT.FLAG"		            : "Y",
            "OWP_WO_WORKMNGT.WUSERSEQ"	            : String(OwpSession('USERSEQ')) ,
            "OWP_WO_WORKMNGT.WORK_USERSEQ"          : String(obj.EMPLOYEE_USERSEQ)  // 2019.12.31 jinseon.lee  
        }]
        ,LOG_PAGEID : 'PopComon'
        ,LOG_WUSERSEQ : OwpSession("USERSEQ") || '1'
        ,LOG_USERIP : OwpStorage.getUserIp()
        ,LOG_REMARK : '인서트LOT번호 수정'
     }

    mutate({
        url: '/updateOwpPnWorkassigDPop' , data
    }).then(res => {
        console.log("res ::::", res);
        callbackfunc(res);
   }).catch(error => console.error(error)); 

}


/* 생산단위수량 업데이트 */
export const setUnixBox = function(obj , callbackfunc ){

    const data = { 
        cudtype : "update",
        "OWP_CM_PRODUCT.UNIT_BOX"         : String(obj.UNIT_QTY) ,
        "OWP_CM_PRODUCT.PROD_SEQ"         : String(obj.PROD_SEQ) ,
        "OWP_CM_PRODUCT.FLAG"             : String('Y'), 
        "OWP_CM_PRODUCT.ORDER_GUBUN_TCD"  : String(obj.ORDER_GUBUN_TCD),
        "OWP_CM_PRODUCT.WUSERSEQ"         : String(OwpSession('USERSEQ')) 
        ,LOG_PAGEID : 'PopComon'
        ,LOG_WUSERSEQ : OwpSession("USERSEQ") || '1'
        ,LOG_USERIP : OwpStorage.getUserIp()
        ,LOG_REMARK : '생산단위수량 수정'
     }

    mutate({
        url: '/updateOwpCmProduct2' , data
    }).then(res => {
        console.log("res ::::", res);
        callbackfunc(res);
   }).catch(error => console.error(error)); 

}

export const loadRemark = function( palletCode , callbackfunc){
    query({
        url: '/loadOwpWoRemark/'+palletCode 
    }).then(res => { 
        callbackfunc(res);
    }).catch(error => {
        console.log('TCL: error', error);
    });  
}

/**
 * @description 오늘 날짜 리턴 YYYY-MM-DD 
 */
export const getToday = function(){
    var someDate = new Date();
        
    var dd = someDate.getDate();
    var mm = someDate.getMonth() + 1;
    var y = someDate.getFullYear();

    mm = mm + "";
    if (mm.length == 1) {
        mm = "0" + mm;
    }

    dd = dd + "";
    if (dd.length == 1) {
        dd = "0" + dd;
    }
    var toDay = y + "-" + mm + "-" + dd;  
    return toDay;
}

/**
 * @description 검색 서브밋 구역에 있는 select box "전체" 옵션이 @@@@@ 들어가는거 처리 
 * @param { 검색 파라미터 } param 
 */
export const convertParam = function(param){
    var p = param; 
    if(p === "@@@@@@" || p === undefined){
         p = "";
    }
    return p;
}
/**
 * @description value가 undefined 거나 null 이거나 하면 "" 을 리턴 
 * @param { 검색 파라미터 } param 
 */
export const isEmpty = function(value){
    var result = value;
    var isRst = false;
    if( result == undefined || result == null || result == ""){
        isRst = true;
    }
    return isRst;
}
/**
 * @description value가 undefined 거나 null 이거나 하면 "" 을 리턴 
 * @param { 검색 파라미터 } param 
 */
export const isEmptyString = function(value){
    var result = value;
    if( result == undefined || result == null || result == ""){
        result = "";
    }
    return result;
}