import React, { Component } from 'react';
import throttle from 'lodash/throttle';
import store from 'store';
import { setNetworkStatus } from 'store/actions';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import FuseAnimate from '../@fuse/components/FuseAnimate/FuseAnimate';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Formsy from 'formsy-react';
import {resetAutoComplate} from "../store/actions/owp";
import {resetAutoComplate2} from "../store/actions/owp";
import {
    OwpMessage,
} from '@wrapper';
import moment from "moment";

const _dispatch = throttle(action => {
    store.dispatch(action);
}, 1000);

const propTypes = {
    leftSide  : PropTypes.node,
    rightSide : PropTypes.node,
    url :   PropTypes.string,
    gridIndex:   PropTypes.number,
    subUrl :PropTypes.string,
    subKeyName :PropTypes.string,
    subValName :PropTypes.string,
    autoSubmit : PropTypes.bool,
    autoDelEvent : PropTypes.bool,
    showResetButton : PropTypes.bool,
    showSubmitButton : PropTypes.bool,
    showDateButton : PropTypes.bool,
    onSearchClick: PropTypes.func,
};

const defaultProps = {
    gridIndex:0,
    autoSubmit:false,
    autoDelEvent:true,
    showResetButton:true,
    showSubmitButton:true,
    showDateButton : true
    ,onSearchClick: event => {},
};

const styles = theme => ({
    root: {},
    button: {
        margin: theme.spacing.unit,
        whiteSpace: 'noWrap'
    },
    leftIcon : {
        marginRight: theme.spacing.unit
    },
    rightIcon: {
        marginLeft: theme.spacing.unit
    },
    iconSmall: {
        fontSize: 16
    }
});

class OwpSearchHeader extends Component {

    componentWillUnmount() {
        if(this.props.autoDelEvent){
           if(window.TGDelEvent() != undefined){
                window.TGDelEvent();
           }  
        }
    }

    dateSet = (value) => {
        _dispatch(resetAutoComplate2(true, value));
    }

    componentDidMount() {

        if(this.props.subUrl!==undefined) {

            const _subUrl = this.props.subUrl;
            const _subKeyName = this.props.subKeyName;
            const _subValName = this.props.subValName;

            setTimeout(function () {

                window.Grids.OnExpand = function (grid, row) {
                    grid.Def.DETAIL.DetailTreeGrid = '<bdo Data_Url="' + process.env.REACT_APP_REST_API_URL
                        + '/' + _subUrl +
                        '?jsondata=%7B%22' + 'VER%22:%222%22,%22' +
                        _subKeyName +
                        '%22:%22'
                        + row[_subValName]
                        + '%22%7D" Page_Method="Get"></bdo>';
                };
                window.Grids.OnDataSend = function (grid, source, data, Func) {
                    _dispatch(setNetworkStatus('START'));
                };
                window.Grids.OnDataReceive = function (grid, source) {
                    _dispatch(setNetworkStatus('DONE'));
                }
            }, 300);
        }

        setTimeout( (_myButton=this.myButton) => {
            this.props.autoSubmit && _myButton.click();
        }, 1000);
    }

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };

    submit = (data) => {
        if(this.props.callfunc){
            this.props.callfunc();
        }

        if(this.props.url===undefined){
            return;
        }
        if(this.props.url === "listOwpCmEquipFromProcTcd"){
            
            let date = data["OWP_CM_STOCK_IO"]["STOCK_DT"]
            let dateSp = date.split(',')
            let dateVali = moment(dateSp[1]).format('YYYY-MM-DD')
            let dateVali2 = moment(dateSp[0]).add(+30,'d').format('YYYY-MM-DD')

            if(dateVali > dateVali2){
                OwpMessage({
                    message : '한 달 이상의 기간은 검색할 수 없습니다.',//text or html
                    variant : 'error'//success error info warning null
                }); 
                return;
            }
        }

        const jsonData = {};
        jsonData['VER'] = '2';

        data && Object.keys(data).map((firstKey, index) => {
            Object.keys(data[firstKey]).map((secondKey, index) => {

                const _data = (data[firstKey][secondKey]==="@@@@@@")?"":""+data[firstKey][secondKey];

                jsonData[firstKey  + '.' +secondKey] = _data.replace(/undefined/g, '');
            });
        });

        const grids = window.GetGrids();

        grids[this.props.gridIndex].Data.Data.Url = process.env.REACT_APP_REST_API_URL+
            '/'+this.props.url+'?jsondata='+encodeURI(JSON.stringify(jsonData))+'&TOKEN='+this.getAccessToken();
        grids[this.props.gridIndex].ReloadBody();

        console.log("gg", grids[this.props.gridIndex].Data.Data.Url)
    };

    reset = () => {

        _dispatch(resetAutoComplate(true));

        if(this.props.callfunc2){
            this.props.callfunc2();
        }

        if(this.props.url===undefined){
            return;
        }
    };

    setRef = ref => {
        this.form = ref;
        if (typeof this.props.forwaredRef === 'funtion') {
          this.props.forwaredRef(ref);
        }
    };

    render() {

        const {classes} = this.props;

        return (
            <React.Fragment>
                <Formsy
                    ref={this.setRef}
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    onReset={this.reset}
                    onSubmit={this.submit}
                    className="flex flex1 w-full items-center justify-between"
                >
                <div className="items-center">
                    {this.props.children}
                </div>
                <div className="flex items-center">
                        <div className="flex items-center">
                        {this.props.showSubmitButton&&(
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button variant="outlined" className={classes.button} type="submit" onClick={this.props.onSearchClick}>
                                    <SearchIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                                    검&nbsp;&nbsp;&nbsp;색
                                </Button>
                            </FuseAnimate>
                            )}
                            {this.props.showResetButton&&(
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button variant="outlined" className={classes.button} type="reset">
                                    <ClearAllIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                                    초기화
                                </Button>
                            </FuseAnimate>
                            )}
                            <input type="submit" className="hidden" ref={(component) => { this.myButton = component; }}/>
                            {this.props.showDateButton&&(
                                <div style={{marginTop: '60px', marginLeft: '-206px', marginRight: '14px', marginBottom: '-24px'}}>
                                    <Button size="small" onClick={()=>{this.dateSet("Today")}}>Today</Button>   
                                    <Button size="small" onClick={()=>{this.dateSet("Week")}}>Week</Button>            
                                    <Button size="small" onClick={()=>{this.dateSet("Month")}}>Month</Button>
                                </div>
                            )}
                        </div>
                </div>
                </Formsy>
            </React.Fragment>
        );
    }
}

OwpSearchHeader.propTypes = propTypes;
OwpSearchHeader.defaultProps = defaultProps;

const StyledOwpSearchHeader = withStyles(styles, { withTheme: true })(OwpSearchHeader);

export default React.forwardRef((props, ref) => (
    <StyledOwpSearchHeader {...props} forwaredRef={ref} />
));
