import { query } from '@owp/api';
import React, { useEffect, useState } from 'react';

export const withQuery = (
    mapDataToProps,
    { shouldGetLoadingProp = false } = {}
) => {
    if (typeof mapDataToProps !== 'function') {
        throw new Error('객체(object)를 리턴하는 함수이어야 합니다.');
    }

    return WrappedComponent => {
        return props => {
            const { query: queryOptions = {}, ...restProps } = props;
            const [state, setState] = useState();

            useEffect(() => {
                const { url, params } = queryOptions;
                shouldGetLoadingProp && setState({ isLoading: true });

                query({ url, params })
                    .then(data => {
                        setState({
                            ...mapDataToProps(data, { ...props }),
                            ...(shouldGetLoadingProp && { isLoading: false }),
                        });
                    })
                    .catch(
                        error =>
                            shouldGetLoadingProp &&
                            setState({ isLoading: false })
                    );
            }, [queryOptions.url, queryOptions.params]);

            return <WrappedComponent {...restProps} {...state} />;
        };
    };
};
