import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import {
    FilledInput,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from '@material-ui/core';
import _ from '@lodash';
import idx from "idx";
import {connect} from "react-redux";
import isEmpty from "lodash/isEmpty";
import {query} from "../@owp/api";
import PropTypes from "prop-types";

class OwpSearchSelectField2 extends Component {
    static defaltProps = {
        commonCodes: [],
    };

    changeValue = (event) => {
        this.props.setValue(event.target.value);
        if ( this.props.onChange )
        {
            this.props.onChange(event);
        }
    };

    items;

    state = {
        urlItems:null
    };

    async componentDidMount() {


        if(this.props.query){

            const {url,params,label,value} = this.props.query;

            try {
                const res = await query({
                    url: encodeURI(url),
                    params
                });

                this.setState({
                    urlItems: res.map(item => ({
                        label: item[label],
                        value: item[value],
                    })),
                })


            } catch (error) {
                console.error('error', error);
            }

        }
    }

    render()
    {

        const importedProps = _.pick(this.props, [
            'autoWidth',
            'children',
            'classes',
            'displayEmpty',
            'input',
            'inputProps',
            'MenuProps',
            'multiple',
            'name',
            'native',
            'onChange',
            'onClose',
            'onOpen',
            'open',
            'renderValue',
            'SelectDisplayProps',
            'value',
            'variant',
            'items'
        ]);

        const canCommonCodes = !isEmpty(this.props.commonCodes);
        if(canCommonCodes&&this.props.groupid){
            this.items = this.props.commonCodes[this.props.groupid];
        }

        // An error message is returned only if the component is invalid
        const errorMessage = this.props.getErrorMessage();
        const value = this.props.getValue()||'';

        this.input = () => {
            switch ( importedProps.variant )
            {
                case "outlined":
                    return <OutlinedInput labelWidth={this.props.label.length * 8} id={this.props.name}/>;
                case "filled":
                    return <FilledInput id={this.props.name}/>;
                default:
                    return <Input id={this.props.name}/>
            }
        };

        return (

            <FormControl error={Boolean(errorMessage)} className={this.props.className} variant={importedProps.variant}>
                {this.props.label && (
                    <InputLabel className={"HKC-TextField"} htmlFor={this.props.name}>{this.props.label}</InputLabel>
                )}
                <Select
                    {...importedProps}
                    value={value}
                    onChange={this.changeValue}
                    input={this.input()}
                    disabled={this.props.disabled}
                    className={"HKC-TextField"}
                >

                    {this.props.isall && (
                        <MenuItem key={0} value=''>전체</MenuItem>
                    )}

                    {importedProps.items && importedProps.items.map((menu,i) =>
                        <MenuItem key={i+1} value={menu.value}>{menu.label}</MenuItem>
                    )}


                    {this.items && this.items.map((menu,i) =>
                        <MenuItem key={i+1} value={menu.value}>{menu.label}</MenuItem>
                    )}

                    {this.state.urlItems && this.state.urlItems.map((menu,i) =>
                        <MenuItem key={i+1} value={menu.value}>{menu.label}</MenuItem>
                    )}

                </Select>
                {Boolean(errorMessage) && (
                    <FormHelperText>{errorMessage}</FormHelperText>
                )}
            </FormControl>
        );

    }

}

OwpSearchSelectField2.propTypes = {
    query: PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    isall:PropTypes.bool,
    disabled:PropTypes.bool
}

OwpSearchSelectField2.defaultProps = {
    className: "textField",
    value: '',
    isall: true,
    disabled: false,
};

function mapStateToProps({ auth }) {
    return {
        commonCodes: idx(auth, _ => _.common.codes),
    };
}

//export default withFormsy(OwpSearchSelectField2);
export default connect(mapStateToProps)(withFormsy(OwpSearchSelectField2));
