import { useIdle } from '@owp/hooks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const DEFAULT_TIMEOUT = window.localStorage.getItem('OWP_SessionExpireMinute');

function AutoLogout({ isLoggedIn, history, timeout = DEFAULT_TIMEOUT }) {

    if(DEFAULT_TIMEOUT > 0){

        useIdle(timeout, isLoggedIn, () => {
            history.replace('/logout');
        });

    }

    return null;
}

function mapStateToProps({ auth }) {
    return {
        isLoggedIn: auth.login.success,
    };
}

export default withRouter(connect(mapStateToProps)(AutoLogout));
