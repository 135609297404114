import FuseUtils from '@fuse/FuseUtils';
import { mutate, query } from '@owp/api';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import OwpMessage from "../@wrapper/OwpMessage";
import history from '../../src/history';
import { OwpSession } from '@wrapper';

/* eslint-disable */


class jwtService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            response => {
                return response;
            },
            err => {
                return new Promise((resolve, reject) => {
                    if (
                        err.response.status === 401 &&
                        err.config &&
                        !err.config.__isRetryRequest
                    ) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit('onAutoLogout', 'Invalid access_token');
                        this.setSession(null);
                    }
                    throw err;
                });
            }
        );
    };

    handleAuthentication = () => {
        let access_token = this.getAccessToken();

        if (!access_token) {
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    createUser = data => {
        return new Promise((resolve, reject) => {
            axios.post('/api/auth/register', data).then(response => {
                if (response.data.user) {
                    this.setSession(response.data.access_token);
                    resolve(response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    /**
     * 임시 사용
     * 로그인 백단 기능 jwt로 개발 완료 되면 아래 주석 된 코드로 변경
     */
    signInWithEmailAndPassword = (email, password, nowIP) => {
        return new Promise((resolve, reject) => {

            // 시스템 설정 DB에서 가져온 값을 셋팅할 경우 아래에 변수로 선언해서 'loadEbsSystemmanageOWP' > 서비스에 값 셋팅하기
            //비밀번호 연속 실패 횟수(기본설정은 '5'로 셋팅)
            let  OWP_MaxLoaginFailCount = 5;

            //자동 로그아웃 시간 설정(분)(기본설정은 5분으로 셋팅 1분=60000 밀리세컨트)
            let  OWP_SessionExpireMinute = 600000;

            query({
                url: '/loadIpxSystemmanageOWP',
                timeout: 1000
            })
            .then(res => {
                //비밀번호 연속 실패 횟수
                OWP_MaxLoaginFailCount = res['MAXLOAGINFAILCOUNT'];

                //자동 로그아웃 시간 설정(분) DB에서 밀리세컨트로 계산해서 로드
                OWP_SessionExpireMinute = res['SESSIONEXPIREMINUTE'];

            })
            .catch(error => {
                console.error('error...', error)
                OwpMessage({
                    message: '서버에 연결할 수 없습니다.',
                    variant: 'error'//success error info warning null
                });
            });


            query({ url: `/loadIpxUserForLogin/${email}/${btoa(password)}/${nowIP}` })
                .then(data => {

                    let loginfail = JSON.parse(localStorage.getItem('loginfail'))||{};

                    if (!data) {

                        let loginfailcnt = ~~(loginfail[email]);

                        loginfail[email] = loginfailcnt+1 ;

                        localStorage.setItem('loginfail', JSON.stringify( loginfail ));

                        //로그인 실패 횟수 제한 해제
                        OwpMessage({
                            //message     : '로그인 '+ (loginfailcnt+1) + '회 실패하였습니다. '+OWP_MaxLoaginFailCount+'회 실패 시 계정이 잠김니다.',//text or html
                            message: '아이디 또는 비밀번호를 다시 확인하세요.',
                            variant: 'error'//success error info warning null
                        });                                        

                        //로그인 실패 시 잠김 해제
                        /*
                        if(loginfail[email] >= OWP_MaxLoaginFailCount){

                            this.updateIPX_User_LockFlag(email).then(res=>{
                                console.log('updateIPX_User_LockFlag res :::::::: ',res);
                                if(res){
                                    OwpMessage({
                                        message     : res.resultMessage,//text or html
                                        variant: 'error'//success error info warning null
                                    });

                                    //사용자 잠금 처리 후 'loginfail'초기화
                                    loginfail[email] = 0 ;
                                    localStorage.setItem('loginfail', JSON.stringify( loginfail ));
                                }
                            });
                        }
                        */

                        //reject(new Error('로그인에 실패 했습니다.'));
                        setTimeout( function () {
                            history.push({
                                pathname: '/login',
                            });
                        }, 1000 );

                    }else{

                        //사용자가 잠금처리되었을 경우
                        if(data['errorCode'] === '-90002' ){
                            OwpMessage({
                                message     : data['errorMessage'],//text or html
                                variant: 'error'//success error info warning null
                            });
                        //존재하지 않는 계정일 경우
                        }else if(data['errorCode'] === '-90004'){
                            OwpMessage({
                                message     : data['errorMessage'],//text or html
                                variant: 'error'//success error info warning null
                            });
                        }else{

                            loginfail[email] = 0 ;
                            localStorage.setItem('loginfail', JSON.stringify( loginfail ));

                            localStorage.setItem('session', JSON.stringify( data ));

                            localStorage.setItem('UserID', email);

                            localStorage.setItem('OWP_SessionExpireMinute', OWP_SessionExpireMinute);

                            this.setSession(data.token);


                            let PERMIT = {};

                            data.navigation.map((m)=>{
                                m.children.map((m)=>{
                                    PERMIT[m.id] = m.permission;
                                });
                            });

                            localStorage.setItem('PERMIT', JSON.stringify(PERMIT));
                            localStorage.setItem('UserIP', nowIP);
                            resolve(data);
                        }
                    }

                })
                .catch(reject);
        });
    };

    // signInWithEmailAndPassword = (email, password) => {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/api/auth', {
    //             data: {
    //                 email,
    //                 password
    //             }
    //         }).then(response => {
    //             if ( response.data.user )
    //             {
    //                 this.setSession(response.data.access_token);
    //                 resolve(response.data.user);
    //             }
    //             else
    //             {
    //                 reject(response.data.error);
    //             }
    //         });
    //     });
    // };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios
                .get('/api/auth/access-token', {
                    data: {
                        access_token: this.getAccessToken(),
                    },
                })
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                });
        });
    };

    updateUserData = user => {
        return axios.post('/api/auth/user/update', {
            user: user,
        });
    };

    updatePw = async user => {

        const data = {
            "cudtype" : "UPDATE",
            'IPX_USER.USERSEQ': ""+user['UserSEQ'],
            'IPX_USER.USERID': ""+user['UserID'],
            'IPX_USER.USERPASSWORD': user['password'],
            'IPX_USER.LASTPASSWORDCHANGEDATE': "NOW",
            'IPX_USER.INITIALPASSWORDCHANGEFLAG': "Y",
        }

        try {
            const result = await mutate({
                url: '/updatePw',
                data
            });
            window.location.reload();
            return result;
        } catch(error) {
            console.error('error', error);
            return null;
        }

    };

    updateIPX_User_LockFlag = async user => {

        try {
            const result = await mutate({
                url: '/updateIPX_User_LockFlag/'+user+'/Y'
            });
            return result;
        } catch(error) {
            console.error('error', error);
            return null;
        }

    };

    setSession = access_token => {

        console.log('access_token :::::::: ',access_token);


        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            sessionStorage.setItem('jwt_access_token', access_token);
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + access_token;
        } else {
            localStorage.removeItem('jwt_access_token');
            sessionStorage.removeItem('jwt_access_token');
            localStorage.removeItem('session');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        // if(!!localStorage.getItem("UserID") && localStorage.getItem("unload") === "1"){
        //     query({
        //         url:`/createUserLog/${OwpSession("USERSEQ")}/${OwpSession(
        //             "LOGSEG"
        //         )}/${localStorage.getItem("UserIP")}/로그아웃` 
        //     })
        // }
        
        //localStorage Clear 처리
        window.localStorage.clear();
        //Session null 처리
        this.setSession(null);
        //화면 새로고침(새로고침을 안하면..재 로그인시 한번 튕기는 현상 발생)
        window.location.reload();
    };

    isAuthTokenValid = access_token => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };
}

const instance = new jwtService();

export default instance;
