import throttle from 'lodash/throttle';
import store from 'store';
import * as Actions from 'store/actions';

const _dispatch = throttle(action => {
    store.dispatch(action);
}, 1000);

export function showMessage(messageData = {}) {
    _dispatch(
        Actions.showMessage({
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            ...messageData,
        })
    );
}
