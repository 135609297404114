import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import _ from '@lodash';
import DatePickerMulti from "../@owp/components/DatePickerMulti";

class OwpDatePickerMulti extends Component {

    render()
    {
        const importedProps = _.pick(this.props, [
            'name',
            'labels',
            'onChange',
            'fullWidth',
            'startDateProps',
            'value',
            'endDateProps',
            'id',
            'startDate',
            'endDate',
            'onClick'

        ]);

        return (
            <DatePickerMulti
                {...importedProps}
                onChange={dates => {
                    this.props.callback(importedProps.name,dates["OWP_DATES_STARTDATE"]+","+dates["OWP_DATES_ENDDATE"]);
                }}
            />
        );
    }
}

export default withFormsy(OwpDatePickerMulti);
