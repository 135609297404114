import * as Actions from '../actions';

function mapProfile({ id, UserID, UserName = '', UserSEQ } = {}) {
    return {
        id,
        email: UserID,
        userId: UserID,
        userSeq: UserSEQ,
        displayName: UserName,
    };
}

function mapUserData({ profile, role = 'guest', shortcuts = [] } = {}) {
    return {
        role,
        shortcuts,
        data: mapProfile(profile),
    };
    // return {
    //     role: 'guest',
    //     data: {
    //         ...mapProfile(profile),
    //         // email: 'guest',
    //         // settings     : {
    //         //     layout          : {
    //         //         style : 'layout2',
    //         //         config: {
    //         //             mode   : 'boxed',
    //         //             scroll : 'content',
    //         //             navbar : {
    //         //                 display: true
    //         //             },
    //         //             toolbar: {
    //         //                 display : true,
    //         //                 position: 'below'
    //         //             },
    //         //             footer : {
    //         //                 display: true,
    //         //                 style  : 'fixed'
    //         //             }
    //         //         }
    //         //     },
    //         //     customScrollbars: true,
    //         //     theme           : {
    //         //         main   : 'greeny',
    //         //         navbar : 'mainThemeDark',
    //         //         toolbar: 'mainThemeDark',
    //         //         footer : 'mainThemeDark'
    //         //     }
    //         // },
    //         shortcuts,
    //     },
    // };
}

const initialState = mapUserData();

const user = function(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_USER_DATA: {
            return mapUserData({ role: 'staff', ...action.payload });
        }
        case Actions.SET_USER_SHORTCUTS: {
            return {
                ...state,
                shortcuts: action.shortcuts,
            };
        }
        case Actions.REMOVE_USER_DATA: {
            return {
                ...initialState,
            };
        }
        case Actions.USER_LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default user;
