import {OwpMessage,OwpExcel,OwpSession} from "../@wrapper";
import {mutate} from "../@owp/api";
import _ from '@lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {font} from '@TGEvent/SpoqaHanSansRegular-normal';
import XLSX from 'xlsx';
import { OwpStorage } from '@owp/common';

const getAccessToken = function () {
    return window.localStorage.getItem('jwt_access_token');
};

export const getGridByID = function(gridId){
    const G = window.GetGrids();
    for(let i=0;i<G.length;i++) {
        if(G[i].Data.id===gridId || G[i].id === gridId){
            return G[i];
        }
    }
    return null;
}

export const OWPTGDataList = function (gridId, restAPI, obj, pageNm) {
    let G;
    let intervalCnt = 0;
    let interval;

    interval = setInterval(() => {
        G = getGridByID(gridId);
        intervalCnt++;

        if (G) {
            clearInterval(interval);
            _OWPTGDataList(G, restAPI, obj, pageNm);
            //console.log('1. clearInterval getGridByID ',gridId,intervalCnt);
        } else {
            if (intervalCnt > 10) {
                clearInterval(interval);
                //console.log('2. clearInterval getGridByID ',gridId,intervalCnt);
            } else {
                //console.log('3. retry getGridByID ',gridId,intervalCnt);
            }
        }

    }, 2000);
};
const _OWPTGDataList = function (G, restAPI, obj, pageNm) {
    //console.log('OWPTGDataList',G);
    const jsonData = _.merge({}, obj, {VER: "2"});
    jsonData['LOG_PAGEID'] = document.location.href.slice(-7);
    jsonData['LOG_WUSERSEQ'] = OwpSession("USERSEQ") || '1';
    jsonData['LOG_USERIP'] = OwpStorage.getUserIp();
    jsonData['LOG_REMARK'] = pageNm;

if (G) {
    G.Data.Data.Url =
        process.env.REACT_APP_REST_API_URL +
        "/" +
        restAPI +
        "?jsondata=" +
        encodeURI(JSON.stringify(jsonData).replace(/@@@@@@/gmi, '')) +
        "DUMMY=" +
        Math.random() * 2.0 * Math.PI +
        "&TOKEN=" +
        getAccessToken();
    G.ReloadBody();
    G.ClearSelection();
    // window.Grids.OnDataSend = function (G, source, data, Func) {
    //     console.log('_OWPTGDataList OnDataSend : ',data);
    //     //_dispatch(setNetworkStatus('START'));
    // }
}
};

//누가 공통화 수정해서 오리지날 추가함
export const OWPTGDataList_Origin = function(gridId,restAPI,obj,pageNm){

    const jsonData = _.merge({},obj,{'VER':'2'});
    jsonData['LOG_PAGEID'] = document.location.href.slice(-7);
    jsonData['LOG_WUSERSEQ'] = OwpSession("USERSEQ") || '1';
    jsonData['LOG_USERIP'] = OwpStorage.getUserIp();
    jsonData['LOG_REMARK'] = pageNm;
    //jsonData['VER'] = '2';
    const G = getGridByID(gridId);
    console.log('OWPTGDataList',G);
    if(G){
        G.Data.Data.Url = process.env.REACT_APP_REST_API_URL+'/'+restAPI+'?jsondata='+encodeURI(JSON.stringify(jsonData))+'&TOKEN='+getAccessToken();
        G.ReloadBody();
    }
}

export const OWPTGDelEvent = function(){
    console.log("OWPTGDelEvent");
    window.TGDelEvent();
}


let isOnSelect = false;
export const OWPTGSelect = function(gridId,callbackfunc){

    window.TGSetEvent('OnSelect',gridId,function(G,row){

        if(!isOnSelect){
            console.log('OWPTGSelect',gridId);
            isOnSelect = true;
            setTimeout(function () {

                const rowCount = G.GetSelRows().length;

                if(rowCount>0){
                    callbackfunc(true,row);
                }else{
                    callbackfunc(false,row);
                }

                isOnSelect = false;

            }, 300);

        }

    } );
}

export const OWPTGDelete = function(gridId,restAPI,rowID,callbackfunc){

    console.log(gridId,restAPI,rowID);
    const G = getGridByID(gridId);

    const rowCount = G.GetSelRows().length;

    if(rowCount>0){
        G.GetSelRows().map((row,i) => { 
            console.log("row>>>>>",  row[rowID]);
            if( row[rowID] == undefined){
                G.RemoveRow(row); 
            }else{
                mutate({
                    url: restAPI+'/'+row[rowID]
                }).then(res => {
                    console.log("ResultData...", res);
                    G.RemoveRow(row);
                    callbackfunc(res);
                }).catch(error => console.error(error));
            }
        });
    }else{
        OwpMessage({
            message     : '선택 한 행이 없습니다.',//text or html
            variant: 'error'//success error info warning null
        });
    }

}

export const OWPTGAddRow = function(gridId,callbackfunc,islast){

    console.log('OWPTGAddRow girdId',gridId);

    //const G = window.GetGrids(girdId)[0];
    const G = getGridByID(gridId);

    let par;
    /*
    islast
    true // 밑으로 데이터 추가
    false // 위로 데이터 추가
    */
    if(islast){

        // 그리드에 데이터가 있을 경우 마지막 데이터 하단에 Insert
        if(G.RowCount > 0){
            par = G.GetNext(G.GetLast(null),G.RowCount+1);

        // 그리드 데이터가 없을 경우
        }else{
            par =  G.GetLast(null);
        }
    }else{
        par = G.GetFirst(null);
    }
    //G.AddRow(par,G.GetSelRows()[0],1);
    const R = G.AddRow(par,par,1);

    R.CanEdit = 1 ;

    callbackfunc(R);

}

export const OWPTGUpdateRow = function(gridId,callbackfunc){

    console.log('OWPTGAddRow girdId',gridId);

    const G = getGridByID(gridId);

    const R = G.GetSelRows()[0];

    R.CanEdit = 1 ;

    callbackfunc(R);

}

export const OWPTGCopyRow = function(gridId,callbackfunc){

    console.log('OWPTGCopyRow girdId',gridId);

    //const G = window.GetGrids(girdId)[0];
    const G = getGridByID(gridId);

    const R = G.CopyRow(G.GetSelRows()[0],G.GetSelRows()[0]);

    R.CanEdit = 1 ;

    callbackfunc(R);

}

export const OWPTGSave = function(gridId,restAPI,format,callbackfunc){

    const G = getGridByID(gridId);

    const _rows = Object.values(G['Rows']);

    const _format = format ;

    _rows.filter((item) => item.Kind == 'Data').map((row) => {
        if(row['Added']==1||row['Changed']==1){

            console.log("_format...", _format,row);

            //let data = _format ;
            let data = Object.assign({}, _format);

            Object.keys(_format).filter((key) => _format[key] === null).map((_key) => {
                console.log(_key,"row[_key]...", row[_key]);
                data[_key] = (row[_key])?""+row[_key]:"";
            });

            //data['TOKEN'] = getAccessToken();
            console.log("data...", data);

            mutate({
                url: restAPI,//+"?TOKEN="+getAccessToken()
                data
            }).then(res => {
                G.ReloadBody();
                console.log("ResultData...", res);
                callbackfunc();
            }).catch(error => console.error(error));
        }
    });

}

export const OWPTGExport = function(gridId,callbackfunc){

    const G = getGridByID(gridId);
    //G.SaveExport("export");

    //const x = document.getElementsByClassName("TSToolExport");
    //const x = document.getElementsByClassName(" TSToolbarRow TSSolidRow");
    //const x = document.getElementsByClassName("TSCellSpaceButton TSType TSButton TSToolHtmlIconSingle TSToolExport1 TSToolAlignSingle TSToolHtmlBase TSToolHtmlIcon1 TSWrap0 TSCellSpaceBase");
    const x = document.querySelectorAll('td.TSCellSpaceButton.TSType.TSButton.TSToolHtmlIconSingle.TSToolExport.TSToolAlignSingle.TSToolHtmlBase.TSToolHtmlIcon.TSWrap0.TSCellSpaceBase');
    for (let i = 0; i < x.length; i++) {
        x[i].style.backgroundColor = "red";

        console.log(x[i])

        //triggerEvent(x[i], 'mouseover');
        //triggerEvent(x[i], 'mousedown');
        //triggerEvent(x[i], 'mouseup');
        //triggerEvent(x[i], 'click');

        //x[i].addEventListener("click", callbackfunc);
    }

    // window.GetGrids.OnMouseDown = function(G,row,col,x,y,Event){
    //     console.log("OnMouseDown: Cell ["+(row?row.id:null)+","+col+"] at ["+x+","+y+"].",1);
    // }

    // const G = window.GetGrids(girdId)[0];
    //
    // //console.log('OWPTGExport',G.Data);
    //
    // const element = document.querySelectorAll('.TSToolExport1')[0];// element.click();


}

export const OWPTGExportExcel = function (gridId, callbackfunc, EnumNoChange) { // 그리드 아이디, 콜백함수, Enum 값 무변토큰

    const G = getGridByID(gridId);

    let cols = [];
    let colsDate = []; // 날짜달력 타입 저장 배열 
    let colsEnum = []; // 콤보박스 타입 저장 배열
    let headers = [];
    let body = [];

    G.GetCols("Visible", "CanExport").map((col, i) => {
        cols.push(col);
        headers.push(G.Rows.Header[col]);
    });
    body.push(headers);

    const _cols = Object.values(G['Cols']);
    _cols.filter((item) => item.Type === 'Date').map((col) => {
        colsDate.push(col["Name"])// 데이터 타입이 날짜 형식인 열의 이름을 저장.
    });
    if (!EnumNoChange) { // Enum 값을 변하지 않겠다고 선언 할 경우 실행 안 함.
        _cols.filter((item) => item.Type === 'Enum').map((col) => {
            colsEnum.push({
                Name: col["Name"], // 파라미터 명
                Enum: col["Enum"], // 라벨 값
                EnumKeys: col["EnumKeys"], // 코드 값
            })// 데이터 타입이 콤보박스 형식인 열의 [파라미터명, 라벨 값, 코드 값] 저장.
        });
    }

    const _rows = Object.values(G['Rows']);
    _rows.filter((item) => item.Kind === 'Data').map((row) => {
        let _rowdata = [];
        cols.map((col, i) => {

            let checkColTypeDate = false; // 데이터 형 변환 구분 변수. (날짜달력일때)
            let checkColTypeEnum = false; // 데이터 형 변환 구분 변수. (콤보박스일때)
            let enumDataLocation = 0; // 콤보박스 저장 변수의 원하는 값 위치 찾기용 변수. (콤보박스일때)

            colsDate.map((typeDate, i) => {
                if (typeDate === col) { // 현재 엑셀에 추가할 데이터의 열에 타입이 날짜 형식인 열일 경우에 구분 변수 값을 true로 변경.
                    checkColTypeDate = true;
                }
            });

            if (!EnumNoChange) { // Enum 값을 변하지 않겠다고 선언 할 경우 실행 안 함.
                if (!checkColTypeDate) { // 날짜 값이 아닐 때.
                    colsEnum.map((typeEnum, i) => {
                        if (typeEnum["Name"] === col) { // typeEnum 배열의 "Name" 값들과 같을 때 실행.
                            checkColTypeEnum = true; // 현재 엑셀에 추가할 데이터의 열에 타입이 Enum 형식인 열일 경우에 구분 변수 값을 true로 변경.
                            enumDataLocation = i; // colsEnum 배열의 몇 번째 값과 이름이 같은지 저장.
                        }
                    });
                }
            }

            if (Boolean(String(row[col]))) { // 해당 데이터가 빈값이 아닐 경우
                if (checkColTypeDate) { // 데이터 타입이 날짜 값인 경우.

                    var date = new Date(row[col]);

                    var year = date.getFullYear();
                    var month = parseInt(date.getMonth() + 1);
                    var day = date.getDate();

                    if (month < 10) month = '0' + month;
                    if (day < 10) day = '0' + day;

                    date = year + "-" + month + "-" + day; // 엑셀에 저장 할 타임스탬프 데이터를 yyyy-mm-dd 형식으로 형 변환.

                    _rowdata.push(date);
                } else if (checkColTypeEnum) { // 데이터 형 변환 구분 변수가 false 이고 저장 할 날짜 데이터의 값이 빈 값이 아닌 경우.

                    const EnumKeys = colsEnum[enumDataLocation]["EnumKeys"].split("|"); // Enum 열의 정보를 저장해둔 배열("colsEnum")의 "enumDataLocation" 번째 값 중 "EnumKeys" 값을 "|" 기준으로 잘라서 배열로 저장.
                    const Enum = colsEnum[enumDataLocation]["Enum"].split("|"); // Enum 열의 정보를 저장해둔 배열("colsEnum")의 "enumDataLocation" 번째 값 중 "Enum" 값을 "|" 기준으로 잘라서 배열로 저장.

                    const arrLocation = EnumKeys.findIndex(i => i === row[col]); // "EnumKeys" 값을 저장한 배열의 값중 row[col]와 같은 값인 χ 번째 위치를 찾는다.
                    _rowdata.push(Enum[arrLocation]); // "Enum" 값을 저장한 배열의 값중 χ 번째 값을 엑셀 출력.
                } else { // 데이터 타입이 날짜, Enum 이 아닐 때
                    _rowdata.push(row[col]);
                }
            } else {// 빈값일 경우
                _rowdata.push("") // 빈값 넣기
            }
        });
        body.push(_rowdata);
    });

    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(body);

    const wscols = [{wpx: 300}];
    sheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(book, sheet, 'sheet1');
    XLSX.writeFile(book, `${gridId}.xlsx`);

};

/**
 * description : 라디오버튼 클릭 외에 모든 컬럼 클릭해도 라디오 버튼 클릭하는것과 
 *               같은 이벤트 일어나도록 함. 
 * author       : jinseon.lee 
 * date         : 2020.07.17 
 */
export const OWPTGSelectRowText = function(grid, row, col){
    if(col !== undefined){
        if( col != 'Panel' && row['Kind'] === 'Data'){  // col != 'Panel' 해당 부분은 라디오 버튼 부분인데 해당 컬럼을 포함하면 이벤트가 두번 일어나므로 제외 row['Kind'] === 'Data' 데이터만 조건 추가 
            grid.SelectRow(row);
            //Focus << 가 셋 타임아웃 없으면 동작 안 함
            setTimeout( function () {
                grid.Focus(grid["Rows"]["XXX"], "")
            }, 0 );
        }
    }
}

export const OWPTGAddRow2 = function(gridId,callbackfunc,islast){

    console.log('OWPTGAddRow girdId',gridId);

    //const G = window.GetGrids(girdId)[0];
    const G = getGridByID(gridId);

    let par;
    /*
    islast
    true // 밑으로 데이터 추가
    false // 위로 데이터 추가
    */
   if(G.RowCount > 0){
        par = G.GetNext(G.GetLast(null),G.RowCount+1);
// 그리드 데이터가 없을 경우
    }else{
        par =  G.GetLast(null);
    }
    //G.AddRow(par,G.GetSelRows()[0],1);
    const R = G.AddRow(par,par,1);

    R.CanEdit = 1 ;

    callbackfunc(R);

}

export const OWPTGExportPDF = function(gridId,callbackfunc){

    const G = getGridByID(gridId);

    //G.SaveExport("export");
    //console.log(G.Data.Layout.Url);
    //console.log(G.Rows);
    //console.log(G.Def);
    //console.log(G.GetCols("Visible","CanExport"));

    //let headerName = [];
    let headers = [];
    let body = [];

    G.GetCols("Visible","CanExport").map((col,i) => {
        //headerName.push(G.Rows.Header[col]);
        headers.push({
                'dataKey' : col,
                'title': G.Rows.Header[col]
        });

    });

    const _rows = Object.values(G['Rows']);
    _rows.filter((item) => item.Kind == 'Data').map((row) => {
        let _rowdata = {};
        G.GetCols("Visible","CanExport").map((col,i) => {
            _rowdata[col] = row[col];
        });
        body.push(_rowdata);
    });

    //console.log('headers',headers);
    //console.log('body',body);

    const doc = new jsPDF('landscape', 'mm', 'a4');

    doc.addFileToVFS('SpoqaHanSansRegular-normal.ttf', font);
    doc.addFont('SpoqaHanSansRegular-normal.ttf', 'SpoqaHanSansRegular', 'normal');
    doc.setFont('SpoqaHanSansRegular');

    doc.autoTable(headers, body, {

        theme: 'grid',

        styles: {

            fontSize: 8,

            font: 'SpoqaHanSansRegular',

            fontStyle: 'normal'

        },

        headerStyles: {

            fontSize: 8,

            font: 'SpoqaHanSansRegular',

            fontStyle: 'normal'

        }

    });

    doc.setProperties({

        title: gridId,

    });

    doc.setFontSize(8);
    //doc.table(1, 1, body, headers, { autoSize: true });
    doc.save(gridId+'.pdf');

    //alert('OWPTGExportPDF');

}

const onDelete = async (url) => {
    const result = await mutate({
        url: url
    }).then(res => {
        console.log("ResultData...", res);
    }).catch(error => console.error(error));
}



setTimeout(function () {


    console.log('document.readyState [3]', document.readyState)

    const grids = window.GetGrids();

    console.log('window.GetGrids()length= ', grids.length);
    console.log('process.env.REACT_APP_REST_API_URL= ', process.env.REACT_APP_REST_API_URL);

    // window.Grids.OnClick = function (grid, row, col, x, y) {
    //     console.log( row , col );
    //     if(row['Kind']==='Header',col==='Panel'){
    //         console.log(grid,'GetSelRows',grid.GetSelRows());
    //     }
    // }

}, 800);


//그리드 로딩 후 서비스 호출
export const OWPonGridLoad = function (gridId, callback) {

    let interval = window.setInterval(()=>{
        let g = getGridByID(gridId);
        if(!!g) {
            //console.log(gridId, "is loading:", g.Loading ? true : false);
            if(g.Loading === 0) {
                window.clearInterval(interval);
                
                if(typeof callback == "function") {
                    callback(g);
                }
            }
        }
    }, 100)
}
