import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import OwpPresetAutocompleteTextField from "../@owp/components/Form/OwpPresetAutocompleteTextField";
import _ from '@lodash';

class OwpSearchAutoComplate extends Component {

    state = {
        _items_:""
    }

    render()
    {
        const importedProps = _.pick(this.props, [
            'name',
            'minInputLength',
            'onChange',
            'onInputChange',
            'isMulti',
            'value',
            'placeholder',
            'query',
            'id'
        ]);

        return (
            <OwpPresetAutocompleteTextField
                {...importedProps}
                onChange={items => {
                    this.setState({_items_:items});
                    let _value=[];
                    items.map((item, index) => {
                        _value.push(item.value);
                        }
                    );

                    this.props.callback(importedProps.name,_value.join());
                }}
                value={this.state._items_}
            />
        );
    }
}

export default withFormsy(OwpSearchAutoComplate);
