import {withFormsy} from 'formsy-react';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const OwpSearchMonthField = ({...restProps }) => {
    return (
        <TextField
            type="month"
            {...restProps}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                max: "9999-12"
            }}
        />
    );
};

OwpSearchMonthField.defaultProps = {
    className: "dateField"
};

export default withFormsy(OwpSearchMonthField);