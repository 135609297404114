import { FuseLoadable } from '@fuse';

export const C100010Config = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                    folded: false,
                    position: 'left',
                },
                toolbar: {
                    display: false,
                    style: 'fixed',
                    position: 'below',
                },
                footer: {
                    display: false,
                    style: 'fixed',
                    position: 'below',
                },
            },
        },
    },
    routes: [
        {
            path: '/pages/C100010',
            component: FuseLoadable({
                loader: () => import('./C100010'),
            }),
        },
    ],
};
