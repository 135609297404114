import {TextFieldFormsy} from '@fuse';
import {Button, Icon, InputAdornment, withStyles} from '@material-ui/core';
import * as Actions from 'auth/store/actions';
import Formsy from 'formsy-react';
import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import jwtService from 'jwtService';
import store from 'store';
import { setCommonCodeData } from 'auth/store/actions/common.actions';
import { setUserData } from 'auth/store/actions/user.actions';
import {FormControlLabel, Radio, RadioGroup, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

import {
    OwpSearchSelectField,
    LoginNumberPad,
    OwpButton,
    OwpMessage,
    OwpDialog,
} from '../../../@wrapper'
import * as TGEvent from "@TGEvent/index";
import * as PopCommon from "../../../@PopCommon/index"; 
import { mutate,  query  } from '@owp/api';
import axios from 'axios';

const styles = theme => ({
    root: {
        width: '100%',
    },
});
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

//let radioValue = '공정'
let radioValue = '부서'
let data = "";
let nowIP = "";

class LoginForm extends Component {
    state = {
         canSubmit: false
        ,keyFlag : 0
        /** keypad 관련 */
       , showNumberPad : false
       , showNumberPad2 : false
       , nowNumberPadInputSelector : null
       , keyboardInput : ''
       , nowUseKeyboard : false
       , numberPadLocation : {
            left : 0,
            top : 0,
            position : 'fixed'
        },
        permiList : [],
        mes : ''  
    };

    constructor(props) {
        super(props);
        this.owpDialog = React.createRef();
        this.state = {
            idItem : []
      };
    }

    form = React.createRef();

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };
 
    onSubmit = model => {
        
        if(this.state.keyFlag === 1){
            return;
        }

        if(this.state.UserID === undefined || this.state.UserID === "" || this.state.pwd === undefined || this.state.pwd === ""){

            return;  
    
        }else{

         
        
            query({
                url: '/listIpxCommoncodePermission',
                params: {
                    jsondata:"{\"IPX_COMMONCODE.GROUPID\" : \"" + this.state.UserID
                        + "\"}"
                }
            }).then(res => {
               
                this.setState({
                    permiList : res 
                })

                if(this.state.permiList.length === 0){ //권한이 없을경우 MES 로그인
                    axios
                        .get("http://ip-api.com/json")
                        .then((response) =>{
                            data = response.data || {};
                            const regex = /\./g;
                            nowIP = data.query.replace(regex, "_");
                            this.props.submitLogin(model, nowIP);
                        })
                        .catch((error)=>{
                            this.props.submitLogin(model, "fail");
                        })
                }else{
                    if(this.state.permiList.length === 1){ //권한이 하나인경우
                        if(this.state.permiList[0]['SYSTEMNM']==='MES'){ //권한이 MES인경우
                            axios
                                .get("http://ip-api.com/json")
                                .then((response) =>{
                                    data = response.data || {};
                                    const regex = /\./g;
                                    nowIP = data.query.replace(regex, "_");
                                    this.props.submitLogin(model, nowIP);
                                })
                                .catch((error)=>{
                                    this.props.submitLogin(model, "fail");
                                })
                        }else{ //권한이 하나이면서 MES 가 아닌경우
                            this.goUrl();
                            //this.login();
                            //setTimeout(() => this.goUrl(), 4000);  
                        }
                    }else{ //권한이 여러개인경우
                        
                        if(localStorage.getItem("OWPDESK") == 'true'){
                            this.props.submitLogin(model, nowIP);
                        }else{
                            this.goUrl();
                        }
                        //this.login();
                        /*
                        this.state.permiList.map((data, i)=>{ 
                
                            if(this.state.permiList[i]['SYSTEMNM']==='MES'){
                                this.setState({
                                    mes : 'mes'
                                })
                            }
                        });
                        
                        if(this.state.mes==='mes'){ //mes가 하나라도 있으면 
                            setTimeout(() => this.props.submitLogin(model), 5000);  
                        }else{ //권한여러개  MES 가 아닌경우
                            setTimeout(() => this.goUrl(), 5000);  
                        }
                       */
                    }
                }  
               
            }).catch(err => {
                console.error(err);
            })
             
            
      
        }

        localStorage.setItem('testPWd', document.getElementById('password').value);
    };

    login = () => {

       // this.state.permiList.map((data, i)=>{ 
           
            if(this.state.permiList[0]['SYSTEMNM']==='MES'){
                console.log('MES',process.env.REACT_APP_API_URL_MES + "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd);
                document.getElementById("ifr_mes").src = process.env.REACT_APP_API_URL_MES + "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + nowIP
            }else if(this.state.permiList[0]['SYSTEMNM']==='SCM'){
                console.log('SCM',process.env.REACT_APP_API_URL_SCM + "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd);
                document.getElementById("ifr_scm").src = process.env.REACT_APP_API_URL_SCM + "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + nowIP
            }else if(this.state.permiList[0]['SYSTEMNM']==='TMS'){
                console.log('TMS');
                //document.getElementById("ifr_scm").src = process.env.REACT_APP_API_URL_TMS + "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd;
            }else if(this.state.permiList[0]['SYSTEMNM']==='CMS'){
                console.log('CMS',process.env.REACT_APP_API_URL_CMS +"/login?userid=ERPAdmin&pwd=Admin2020");
                document.getElementById("ifr_scm").src = process.env.REACT_APP_API_URL_CMS+ "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + nowIP
                //document.getElementById("ifr_cms").src = process.env.REACT_APP_API_URL_CMS +"/login?userid=ERPAdmin&pwd=Admin2020";
            }else{
                console.log('PARTNER',process.env.REACT_APP_API_URL_PARTNER +"/login?userid=ERPAdmin&pwd=Admin2020");
                document.getElementById("ifr_scm").src = process.env.REACT_APP_API_URL_PARTNER+ "/login?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + nowIP
                //document.getElementById("ifr_cms").src = process.env.REACT_APP_API_URL_CMS +"/login?userid=ERPAdmin&pwd=Admin2020";
            }

        // });
       
    }
    /*
    goUrl = () => {
        
        this.state.permiList.map((data, i)=>{ 
            if(i===0){
                
                if(this.state.permiList[i]['SYSTEMNM']==='MES'){
                    console.log('GO MES');
                    window.location.href = process.env.REACT_APP_API_URL_MES
                }else if(this.state.permiList[i]['SYSTEMNM']==='SCM'){
                    console.log('GO SCM');
                    window.location.href = process.env.REACT_APP_API_URL_SCM
                }else if(this.state.permiList[i]['SYSTEMNM']==='TMS'){
                    console.log('GO TMS');
                    window.location.href = process.env.REACT_APP_API_URL_TMS
                }else if(this.state.permiList[i]['SYSTEMNM']==='CRM'){
                    console.log('GO CRM');
                    window.location.href = process.env.REACT_APP_API_URL_CMS
                }
            }
         });
       
    }
    */
    goUrl = () => {

        this.state.permiList.map((data, i)=>{ 
            if(i===0){
                if(this.state.permiList[i]['SYSTEMNM']==='MES'){
                    console.log('GO MES');
                    window.location.href =  process.env.REACT_APP_API_URL_MES + "/pages/P100000?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + (nowIP === "" ? "fail" : nowIP)
                }else if(this.state.permiList[i]['SYSTEMNM']==='SCM'){
                    console.log('GO SCM');
                    window.location.href = process.env.REACT_APP_API_URL_SCM + "/pages/P100000?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + (nowIP === "" ? "fail" : nowIP)
                }else if(this.state.permiList[i]['SYSTEMNM']==='TMS'){
                    console.log('GO TMS');
                    window.location.href = process.env.REACT_APP_API_URL_TMS
                }else if(this.state.permiList[i]['SYSTEMNM']==='CMS'){
                    console.log('GO CRM');
                    window.location.href = process.env.REACT_APP_API_URL_CMS + "/pages/P100000?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + (nowIP === "" ? "fail" : nowIP)
                }else{
                    console.log('GO PARTNER');
                    window.location.href = process.env.REACT_APP_API_URL_PARTNER + "/pages/P100000?userid=" +this.state.UserID + "&pwd=" + this.state.pwd + "&ip=" + (nowIP === "" ? "fail" : nowIP)
                }
            }
         }); 
       
    }

    keyCNT = () =>{
        this.state.keyFlag = 0;
    }

    componentDidMount() {
        const _daialogCostNm = this.openDialog;

        localStorage.removeItem('pwchange');
        const userID = window.localStorage.getItem('UserID');
        if(userID){
           
            this.setState({UserID:userID});
            
        }
        this.fair_setting()

        axios
            .get("http://ip-api.com/json")
            .then((response) =>{
                data = response.data || {};
                const regex = /\./g;
                nowIP = data.query.replace(regex, "_");
            })
            .catch((error)=>{
                nowIP = "fail"
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.login.error &&
            (this.props.login.error.email || this.props.login.error.password)
        ) {
            this.form.updateInputsWithError({
                ...this.props.login.error,
            });

            this.props.login.error = null;
            this.disableButton();
        }

        if (this.props.user.role !== 'guest') {
            const pathname =
                this.props.location.state &&
                this.props.location.state.redirectUrl;
            // ? this.props.location.state.redirectUrl
            // : '/';

            if (pathname) {
                this.props.history.push({
                    pathname,
                });
            }
        }
        return null;
    }

    // 업체검색 팝업 
    openDialog = () =>{ 
        this.owpDialog.handleClickOpen();
    } 

    // 공정명의 값을 선택 시 설비명에 데이터 항목이 생김.
    fair_setting = () => {
        let groupId = 'A001000'
        let remark = ''

        if(radioValue === '부서'){
            groupId = 'A003000'
            remark = '협력업체'
        }
        query({
            url: '/listIpxCommoncode',
            params: {
                jsondata:"{\"IPX_COMMONCODE.DESCRIPTION\" : \"" + radioValue
                    + "\", \"IPX_COMMONCODE.GROUPID\" : \"" + groupId
                    + "\", \"IPX_COMMONCODE.REMARK\" : \"" + remark
                    + "\"}"
            }
        }).then(res => {
            this.setState({
                fairItem: res.map(item => ({
                    label: item["IPX_COMMONCODE.CODENM"],
                    value: item["IPX_COMMONCODE.CODEID"],
                })),
            })
        }).catch(err => {
            console.error(err);
        })
    };

    id_setting = () => {
        if(radioValue === '부서'){
            query({
                url: '/listIpxUser',
                params: {
                    jsondata:"{\"IPX_USER.DEPTTEAM\" : \"" + this.state.fairInput
                        + "\", \"IPX_USER.FLAG\" : \"" + 'Y' 
                        + "\", \"IPX_USER.GROUPDEPTID\" : \"" + 'D099009' 
                        +"\"}"
                }
            }).then(res => {
                this.setState({
                    idItem: res.map(item => ({
                        label: item["IPX_USER.USERNAME"],
                        value: item["IPX_USER.USERID"],
                    })),
                })
            }).catch(err => {
                console.error(err);
            })
        }else{
            query({
                url: '/listIpxUser',
                params: {
                    jsondata:"{\"IPX_USER.DEPTID\" : \"" + this.state.fairInput
                        + "\", \"IPX_USER.FLAG\" : \"" + 'Y' 
                        + "\", \"IPX_USER.GROUPDEPTID\" : \"" + 'D099009' 
                        +"\"}"
                }
            }).then(res => {
                this.setState({
                    idItem: res.map(item => ({
                        label: item["IPX_USER.USERNAME"],
                        value: item["IPX_USER.USERID"],
                    })),
                })
            }).catch(err => {
                console.error(err);
            })
        }
       
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value}, ()=> {
            this.setState({ UserID :  this.state["idSelect"] })
         });
    };

     /**
     * KeyPad 
     */

    changeNum = (event,type) => {

        let num;
        let idx; 
        let nowUseKeyboardChk = false;

        if(type !== 'input'){ // 가상 키보드로 입력 중
            num = this.state.keyboardInput;
            nowUseKeyboardChk = true
            this.state.keyFlag = 1;
        }else   // 키보드로 입력 중
        {
            this.state.showNumberPad = false
            num = event.target.value;
            nowUseKeyboardChk = false;
            this.state.keyFlag = 0;
        }
        this.setState({ pwd : num })
    };

    changeNum2 = (event,type) => {

        let num2;
        let idx; 
        let nowUseKeyboardChk = false;

        if(type !== 'input'){ // 가상 키보드로 입력 중
            num2 = this.state.keyboardInput2;
            this.state.keyFlag = 1;
        }else   // 키보드로 입력 중
        {
            this.state.showNumberPad2 = false
            num2 = event.target.value;
            nowUseKeyboardChk = false;
            this.state.keyFlag = 0;
        }
        this.setState({ UserID : num2 })

    };

    showNumberPad = (event) => {

        this.setState({
            showNumberPad:true,
            showNumberPad2:false,
            nowUseKeyboard:true,
            nowNumberPadInputSelector:event.target,
            numberPadLocation : {
                left : 85,
                top : 620
        }
        });
    }

    showNumberPad2 = (event) => {

        this.setState({
            showNumberPad:false,
            showNumberPad2:true,
            nowUseKeyboard:true,
            nowNumberPadInputSelector:event.target,
            numberPadLocation : {
                left : 85,
                top : 620
        }
        });
    }

    closeKeyboard = () => {
        this.setState({
            showNumberPad : false,
            showNumberPad2 : false,
            nowNumberPadInputSelector:null,
            nowUseKeyboard : false
        });
    }

    keyboardInput = (value) => {

        this.setState({
            keyboardInput:value
        },this.changeNum);
    }

    keyboardInput2 = (value) => {
        this.setState({
            keyboardInput2:value
        },this.changeNum2);
    }

    selectId = () => {
        this.owpDialog.handleClose();

        this.setState({
            UserID : this.state.selId,
            fairInputID : this.state.selVal
        })

        document.getElementById("password").focus();
    }

    render() {
        const { classes } = this.props;
        const { canSubmit } = this.state;
        const numberPadSection = this.state.showNumberPad ? (<LoginNumberPad nowUseKeyboard={this.state.nowUseKeyboard} numberPadLocation={this.state.numberPadLocation} closeKeyboard={this.closeKeyboard} nowNumberPadInputSelector={this.state.nowNumberPadInputSelector} keyboardInput={this.keyboardInput}/>) : (null);
        const numberPadSection2 = this.state.showNumberPad2 ? (<LoginNumberPad nowUseKeyboard={this.state.nowUseKeyboard} numberPadLocation={this.state.numberPadLocation} closeKeyboard={this.closeKeyboard} nowNumberPadInputSelector={this.state.nowNumberPadInputSelector} keyboardInput={this.keyboardInput2}/>) : (null);
        const {search} = this.props.location;
        
        if(search !== ''){
            this.state.UserID = search.split("&")[0].split("=")[1];
            this.state.pwd = search.split("&")[1].split("=")[1];

            if(this.props.user.role!=='staff'){

                jwtService.signInWithEmailAndPassword(this.state.UserID, this.state.pwd, nowIP).then(async user => {
                
                    this.props.user.role ='staff';
                    //store.dispatch(setNavigation(mapNav(user)));
                    //window.location.href = process.env.REACT_APP_API_URL_MES
                    store.dispatch(setUserData(user));
                    localStorage.setItem('testPWd', this.state.pwd);
                    const result = store.dispatch({
                        type: LOGIN_SUCCESS,
                    });
    
                    try {
                        store.dispatch(
                            setCommonCodeData(
                                await query({
                                    url: '/listIPX_CommonCodeTotalAC',
                                })
                            )
                        );
                    } catch (error) {
                        console.error(error);
                    }
    
                    window.location.reload();
    
                   

                });
            }
        }
        let left_list_body = "";

        left_list_body = this.state.idItem.map((info, i)=>(
            <div style={{padding: '10px', float: 'left', width: '11%', cursor:"pointer"}}
                onClick={()=>{ 
                    this.setState({
                        selId : info['value'],
                        selVal : info['label']
                    },()=>this.selectId() )
                }}>
                <tr>
                    <td className="t_right" style={{padding: '10px', fontSize : '14px'}}>{info['label']}</td>
                </tr>
            </div>
        ));

      
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Formsy
                        onValidSubmit={this.onSubmit}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        ref={form => (this.form = form)}
                        className="flex flex-col justify-center w-full"
                    >

                        <div> 
                            {numberPadSection} {/** keypad 관련 */}
                        </div>

                        <div> 
                            {numberPadSection2} {/** keypad 관련 */}
                        </div>
                        {/**
                        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                            <RadioGroup
                                row
                                value={radioValue}
                                style={{}}
                                onChange={event => {
                                    radioValue = event.target.value
                                    this.fair_setting()
                                }}
                            >
                                <FormControlLabel
                                    label="공정"
                                    value="공정"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="부서"
                                    value="부서"
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </div>
                        */}

                        <OwpSearchSelectField
                            className="mb-16"
                            label="공정/부서 선택"
                            name="fair"
                            onChange={value => {
                                this.setState({fairInput: value.target.value}, () => {
                                    this.id_setting();               
                                });  
                            }}
                            items={this.state.fairItem}
                            value={this.state.fairInput}
                            variant="outlined"
                            autoComplete="off"
                        />  

                        {/* <OwpSearchSelectField
                            className="mb-16"
                            label="아이디 선택"
                            name="idSelect"
                            onChange={this.handleChange}
                            items={this.state.idItem}
                            value={this.state.fairInputID}
                            isall={false}
                            variant="outlined"
                            autoComplete="off"
                        />   */}

                        <TextFieldFormsy
                            className="mb-16"
                            label="아이디 선택"
                            name="idSelect"
                            onChange={this.handleChange}
                            onClick={()=>{ 
                                const _daialogCostNm = this.openDialog;
                                _daialogCostNm();
                            }} 
                            value={this.state.fairInputID}
                            isall={false}
                            variant="outlined"
                            autoComplete="off"
                        />  

                        <TextFieldFormsy
                            className="mb-16"
                            type="text"
                            name="email"
                            label="아이디"
                            value={this.state.UserID}
                            //onFocus={this.showNumberPad} 
                            onClick={this.showNumberPad2}
                            onChange={(event)=>this.changeNum2(event,'input')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className="text-20" color="action">
                                            email
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            //required
                            autoComplete="off"
                        />

                        <TextFieldFormsy
                            className="mb-16"
                            id='password'
                            type="password"
                            name="password"
                            label="패스워드"
                            value={this.state.pwd}
                            //onFocus={this.showNumberPad} 
                            onClick={this.showNumberPad}
                            onChange={(event)=>this.changeNum(event,'input')}
                            validations={{
                                minLength: 4,
                                //matchRegexp: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/
                            }}
                            validationErrors={{
                                minLength: '패스워드는 최소 4자 이상이어야 합니다.',
                                matchRegexp: '패스워드는 문자와 숫자의 조합이어야합니다.'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className="text-20" color="action">
                                            vpn_key
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            //required
                        />

                        <Button
                            onClick={this.keyCNT}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className="w-full mx-auto mt-16 normal-case"
                            aria-label="LOG IN"
                            //disabled={!canSubmit}
                            value="legacy"
                        >
                            로그인
                        </Button>
                    </Formsy>
                    { /*<div style={{display: 'none'}}>*/}
                       <div style={{display: 'none'}}>
                            <iframe id="ifr_mes" src=""/> 
                       </div>
                       <div style={{display: 'none'}}>
                            <iframe id="ifr_scm" src=""/> 
                       </div>
                       <div style={{display: 'none'}}>
                            <iframe id="ifr_tms" src=""/> 
                       </div>
                       <div style={{display: 'none'}}>
                            <iframe id="ifr_cms" src=""/> 
                       </div>
                </div>

                {/** start 업체검색 팝업 */}
                <OwpDialog
                    title = "아이디 선택"
                    disableBackdropClick={false}
                    dialogActions={
                        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                            <React.Fragment>                                     
                                    <OwpButton onClick={()=>{ 
                                        console.log(this.state.idItem)
                                    }} color="secondary" variant="contained">
                                        확인
                                    </OwpButton>
                                    <OwpButton onClick={()=>{ this.owpDialog.handleClose(); }} 
                                            color="default" variant="outlined">
                                        닫기
                                    </OwpButton>
                                
                            </React.Fragment>
                        </div>
                    } innerRef={ref => this.owpDialog = ref}
                    width="1800px"
                    height="600px"
                >
                        
                    <React.Fragment>
                        <div>
                            <div style={{display: "flex", justifyContent: "center", marginBottom: '20px'}}>                                      
                                <table className="table table-striped table-bordered table-advance tmain">                           
                                    <tbody>
                                        {left_list_body}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                </OwpDialog>
                {/** end 업체검색 팝업 */}   
            </React.Fragment>
        );
        
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitLogin: Actions.submitLogin,
        },
        dispatch
    );
}

function mapStateToProps({ auth }) {
    return {
        login: auth.login,
        user: auth.user,
    };
}

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(LoginForm)
    )
);
