import React, {Component} from 'react';
import PropTypes from "prop-types";
import {CheckboxFormsy} from '@fuse';

const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired
        })
    ),
};

const defaultProps = {
    items : [],
    className: "textField"
};

class OwpSearchCheckBox extends Component {

    render()
    {
        const {items,className} = this.props;

        return (
            <React.Fragment>
                {items && items.map((menu,i) =>
                    <CheckboxFormsy key={i}
                                    checked={true}
                        name={menu.name}
                        label={menu.label}
                        className={className}
                    />
                )}
            </React.Fragment>
        );
    }
}

OwpSearchCheckBox.propTypes = propTypes;
OwpSearchCheckBox.defaultProps = defaultProps;

export default OwpSearchCheckBox;
