import {FuseLoadable} from '@fuse';

export const P100000Config = {
    settings: {
        layout: {
          config: {
            scroll: "no",
            navbar: {
              display: false,
              folded: false,
              position: false,
            },
            toolbar: {
              display: false,
              style: false,
              position: false,
            },
            footer: {
              display: false,
              style: false,
              position: false,
            },
          },
        },
      },
    routes  : [
        {
            path     : '/pages/P100000',
            component: FuseLoadable({
                loader: () => import('./P100000')
            })
        }
    ]
};
