import { FuseLoadable } from "@fuse";

export const C111020Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/pages/C111020',
            component: FuseLoadable({
                loader: () => import('./C111020')
            })
        }
    ]
};
