import { FuseLoadable } from '@fuse';

export const C104020Config = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/pages/C104020',
            component: FuseLoadable({
                loader: () => import('./C104020'),
            }),
        },
    ],
};
