import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

const propTypes = {
    id: PropTypes.string,
    LayoutUrl: PropTypes.string,
    LayoutData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    data: PropTypes.array,
    DataUrl: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    onMount: PropTypes.func,
    useReloadData: PropTypes.bool,
};

const defaultProps = {
    id: 'treeGridWrapper',
    LayoutUrl: '/assets/data/owp_000000Def.xml',
    data: [],
    DataUrl: '/assets/data/owp_000000Data.xml',
    height: '100%',
    width: '100%',
    onMount: () => {},
    useReloadData: false,
};

class OwpTreeGrid extends Component {
    createTreeGrid = () => {
        const { id, LayoutUrl, LayoutData, DataUrl, data, width, height, onMount, ...restProps } = this.props;
        
        const _DataUrl = DataUrl.includes('assets')
            ? DataUrl
            : process.env.REACT_APP_REST_API_URL + '/' + DataUrl;

        this.table = window.TreeGrid(
            {
                id: id,
                Layout: !!LayoutData ? { Data: LayoutData } : { Url: LayoutUrl },
                Data: isEmpty(data)
                    ? {
                          Url: _DataUrl,
                      }
                    : { Data: { Body: [data] } },
                Text: { Url: '/assets/vendors/Grid/Text.xml' },
                width: width,
                height: height,
                Debug: '',
                ...restProps,
            },
            id,
            id
        );

        this.table.TmpFocus = 0;
        //gridInstanceRef.current = this.table;
        onMount(this.table);
    };

    componentDidMount() {
        const _createTreeGrid = this.createTreeGrid;

        setTimeout(function() {
            _createTreeGrid();
        }, 100);
    }

    componentWillUnmount() {
        this.table && this.table.Dispose();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.useReloadData &&
            !isEqual(prevProps.data, this.props.data) &&
            !isEmpty(get(this.table, 'Data.Data.Data.Body'))
        ) {
            this.table.Data.Data.Data.Body = [this.props.data || []];
            this.table.ReloadBody();
        }
    }

    render() {
        const { id, height, width } = this.props;
        const style = {
            width: width,
            height: height,
        };

        return <div id={id} style={style} />;
    }
}

OwpTreeGrid.propTypes = propTypes;
OwpTreeGrid.defaultProps = defaultProps;

export default OwpTreeGrid;
