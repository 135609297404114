import { Error404PageConfig } from '../main/content/pages/errors/404/Error404PageConfig';
import { Error500PageConfig } from '../main/content/pages/errors/500/Error500PageConfig';


/*한국기능공사*/
//import { C0202000Config } from '../main/content/pages/C0202000/C0202000Config';
import {P100000Config} from '../main/content/pages/P100000/P100000Config';

import { C100010Config } from '../main/content/pages/C100010/C100010Config';
import { C100020Config } from '../main/content/pages/C100020/C100020Config';
import { C100030Config } from '../main/content/pages/C100030/C100030Config';


import { C101010Config } from '../main/content/pages/C101010/C101010Config';
import { C101020Config } from '../main/content/pages/C101020/C101020Config';
import { C101023Config } from '../main/content/pages/C101023/C101023Config';//생산현황
import { C101024Config } from '../main/content/pages/C101024/C101024Config';//성과지표
import { C101030Config } from '../main/content/pages/C101030/C101030Config';
import { C101040Config } from '../main/content/pages/C101040/C101040Config';
import { C101050Config } from '../main/content/pages/C101050/C101050Config';

import { C102010Config } from '../main/content/pages/C102010/C102010Config';
import { C102020Config } from '../main/content/pages/C102020/C102020Config';
import { C102031Config } from '../main/content/pages/C102031/C102031Config';
import { C102032Config } from '../main/content/pages/C102032/C102032Config';
import { C102033Config } from '../main/content/pages/C102033/C102033Config';
import { C102034Config } from '../main/content/pages/C102034/C102034Config';
import { C102035Config } from '../main/content/pages/C102035/C102035Config';
import { C102036Config } from '../main/content/pages/C102036/C102036Config';
import { C102037Config } from '../main/content/pages/C102037/C102037Config';
import { C102038Config } from '../main/content/pages/C102038/C102038Config';
import { C102039Config } from '../main/content/pages/C102039/C102039Config';
import { C102041Config } from '../main/content/pages/C102041/C102041Config'; //안전재고 설정
import { C102042Config } from '../main/content/pages/C102042/C102042Config'; //계획대비실적
import { C102043Config } from '../main/content/pages/C102043/C102043Config'; //생산계획반영(평균출하)
import { C102044Config } from '../main/content/pages/C102044/C102044Config'; //평균출하 생산계획등록
import { C102045Config } from '../main/content/pages/C102045/C102045Config'; //설비별 재공품/금형설정

import { C102040Config } from '../main/content/pages/C102040/C102040Config';
import { C102050Config } from '../main/content/pages/C102050/C102050Config';
import { C102060Config } from '../main/content/pages/C102060/C102060Config';
import { C102070Config } from '../main/content/pages/C102070/C102070Config';
import { C102080Config } from '../main/content/pages/C102080/C102080Config';
import { C102090Config } from '../main/content/pages/C102090/C102090Config';
import { C102100Config } from '../main/content/pages/C102100/C102100Config'; // 작업지시 
import { C102101Config } from '../main/content/pages/C102101/C102101Config'; // 작업지시 윈테크 원테크 용
import { C102110Config } from '../main/content/pages/C102110/C102110Config';  
import { C102120Config } from '../main/content/pages/C102120/C102120Config'; //완제품 생산실적조회
import { C102130Config } from '../main/content/pages/C102130/C102130Config'; //완제품 생산실적등록

import { C103010Config } from '../main/content/pages/C103010/C103010Config';
import { C103011Config } from '../main/content/pages/C103011/C103011Config';
import { C103020Config } from '../main/content/pages/C103020/C103020Config';
import { C103030Config } from '../main/content/pages/C103030/C103030Config';  // 열처리작업일보 
import { C103040Config } from '../main/content/pages/C103040/C103040Config';  // 행잉작업일보
import { C103050Config } from '../main/content/pages/C103050/C103050Config';  // 사출작업일보 
import { C103140Config } from '../main/content/pages/C103140/C103140Config';  // 사출 건조투입
import { C103141Config } from '../main/content/pages/C103141/C103141Config';  // 사출 배합
import { C103060Config } from '../main/content/pages/C103060/C103060Config';  // 웰딩(용접)작업일보 
import { C103070Config } from '../main/content/pages/C103070/C103070Config';  // 조립작업일보 
import { C103080Config } from '../main/content/pages/C103080/C103080Config';  // 검사작업일보 
import { C103081Config } from '../main/content/pages/C103081/C103081Config';  // 리워크 작업일보 
import { C103082Config } from '../main/content/pages/C103082/C103082Config';  // 사상.GP 작업일보 
import { C103083Config } from '../main/content/pages/C103083/C103083Config';  // 표면처리 작업일보
import { C103084Config } from '../main/content/pages/C103084/C103084Config';  // 연마 작업일보 
import { C103085Config } from '../main/content/pages/C103085/C103085Config';  // 공정별 작업일보 
import { C103086Config } from '../main/content/pages/C103086/C103086Config';  // 공정별 작업일보 
import { C103087Config } from '../main/content/pages/C103087/C103087Config';  // 원테크 작업일보 

import { C103090Config } from '../main/content/pages/C103090/C103090Config';
import { C103095Config } from '../main/content/pages/C103095/C103095Config';
//import { C103096Config } from '../main/content/pages/C103096/C103096Config'; //열처리작업정보2
import { C103097Config } from '../main/content/pages/C103097/C103097Config'; //설비별 소모품 투입
import { C103100Config } from '../main/content/pages/C103100/C103100Config';
import { C103110Config } from '../main/content/pages/C103110/C103110Config';
import { C103120Config } from '../main/content/pages/C103120/C103120Config';
import { C103130Config } from '../main/content/pages/C103130/C103130Config';
import { C103150Config } from '../main/content/pages/C103150/C103150Config'; // 공정이동정표관리 
import { C103155Config } from '../main/content/pages/C103155/C103155Config'; // 공정이동정표관리 
import { C103160Config } from '../main/content/pages/C103160/C103160Config'; // 공정이동정표관리 
import { C103170Config } from '../main/content/pages/C103170/C103170Config'; 
import { C103180Config } from '../main/content/pages/C103180/C103180Config'; 
import { C103190Config } from '../main/content/pages/C103190/C103190Config'; 
import { C103200Config } from '../main/content/pages/C103200/C103200Config'; //비가동현황
import { C103210Config } from '../main/content/pages/C103210/C103210Config'; //비가동작업지시
import { C103300Config } from '../main/content/pages/C103300/C103300Config'; //불량현황

import { C103400Config } from '../main/content/pages/C103400/C103400Config'; //비가동작업지시
import { C103500Config } from '../main/content/pages/C103500/C103500Config'; //일상점검관리
import { C103600Config } from '../main/content/pages/C103600/C103600Config'; //불량봉쇄관리
import { C103700Config } from '../main/content/pages/C103700/C103700Config'; //표면처리작업일보
import { C103800Config } from '../main/content/pages/C103800/C103800Config'; 
import { C103900Config } from '../main/content/pages/C103900/C103900Config'; //MES재고관리

import { C104010Config } from '../main/content/pages/C104010/C104010Config';
import { C104020Config } from '../main/content/pages/C104020/C104020Config';
import { C104030Config } from '../main/content/pages/C104030/C104030Config';
import { C104040Config } from '../main/content/pages/C104040/C104040Config';
import { C104050Config } from '../main/content/pages/C104050/C104050Config';
import { C104060Config } from '../main/content/pages/C104060/C104060Config';
import { C104070Config } from '../main/content/pages/C104070/C104070Config';
import { C104080Config } from '../main/content/pages/C104080/C104080Config';
import { C104085Config } from '../main/content/pages/C104085/C104085Config';
import { C104086Config } from '../main/content/pages/C104086/C104086Config';
import { C104087Config } from '../main/content/pages/C104087/C104087Config';
import { C104088Config } from '../main/content/pages/C104088/C104088Config';
import { C104089Config } from '../main/content/pages/C104089/C104089Config';
import { C104090Config } from '../main/content/pages/C104090/C104090Config';
import { C104093Config } from '../main/content/pages/C104093/C104093Config';
import { C104095Config } from '../main/content/pages/C104095/C104095Config';
import { C104100Config } from '../main/content/pages/C104100/C104100Config';
import { C104110Config } from '../main/content/pages/C104110/C104110Config';
import { C104120Config } from '../main/content/pages/C104120/C104120Config';
import { C104130Config } from '../main/content/pages/C104130/C104130Config';
import { C104140Config } from '../main/content/pages/C104140/C104140Config';
import { C104145Config } from '../main/content/pages/C104145/C104145Config';
import { C104150Config } from '../main/content/pages/C104150/C104150Config';


import { C105011Config } from '../main/content/pages/C105011/C105011Config';
import { C105012Config } from '../main/content/pages/C105012/C105012Config';
import { C105013Config } from '../main/content/pages/C105013/C105013Config';
import { C105021Config } from '../main/content/pages/C105021/C105021Config';
import { C105022Config } from '../main/content/pages/C105022/C105022Config';
import { C105023Config } from '../main/content/pages/C105023/C105023Config';
import { C105031Config } from '../main/content/pages/C105031/C105031Config';
import { C105032Config } from '../main/content/pages/C105032/C105032Config';
import { C105033Config } from '../main/content/pages/C105033/C105033Config';
import { C105034Config } from '../main/content/pages/C105034/C105034Config';
import { C105041Config } from '../main/content/pages/C105041/C105041Config';
import { C105042Config } from '../main/content/pages/C105042/C105042Config';
import { C105050Config } from '../main/content/pages/C105050/C105050Config';
import { C105060Config } from '../main/content/pages/C105060/C105060Config';

import { C106010Config } from '../main/content/pages/C106010/C106010Config';
import { C106020Config } from '../main/content/pages/C106020/C106020Config';

import { C107010Config } from '../main/content/pages/C107010/C107010Config';
import { C107020Config } from '../main/content/pages/C107020/C107020Config';
import { C107030Config } from '../main/content/pages/C107030/C107030Config';
import { C107040Config } from '../main/content/pages/C107040/C107040Config';
import { C107050Config } from '../main/content/pages/C107050/C107050Config';
import { C107060Config } from '../main/content/pages/C107060/C107060Config';
import { C107070Config } from '../main/content/pages/C107070/C107070Config';

import { C107510Config } from '../main/content/pages/C107510/C107510Config'; // 내수 출하지시 
import { C107520Config } from '../main/content/pages/C107520/C107520Config'; // 내수 출하지시 
import { C107540Config } from '../main/content/pages/C107540/C107540Config'; // 내수 출하지시 


import { C108010Config } from '../main/content/pages/C108010/C108010Config';
import { C108020Config } from '../main/content/pages/C108020/C108020Config';
import { C108030Config } from '../main/content/pages/C108030/C108030Config';
import { C108040Config } from '../main/content/pages/C108040/C108040Config';
import { C108050Config } from '../main/content/pages/C108050/C108050Config';
import { C108060Config } from '../main/content/pages/C108060/C108060Config';

import { C109010Config } from '../main/content/pages/C109010/C109010Config';
import { C109020Config } from '../main/content/pages/C109020/C109020Config';
import { C109025Config } from '../main/content/pages/C109025/C109025Config';
import { C109030Config } from '../main/content/pages/C109030/C109030Config';
import { C109040Config } from '../main/content/pages/C109040/C109040Config';
import { C109050Config } from '../main/content/pages/C109050/C109050Config';
import { C109060Config } from '../main/content/pages/C109060/C109060Config';
import { C109070Config } from '../main/content/pages/C109070/C109070Config';
import { C109080Config } from '../main/content/pages/C109080/C109080Config';
import { C109090Config } from '../main/content/pages/C109090/C109090Config';
import { C109100Config } from '../main/content/pages/C109100/C109100Config';
import { C109120Config } from '../main/content/pages/C109120/C109120Config';
import { C109130Config } from '../main/content/pages/C109130/C109130Config';
import { C109150Config } from '../main/content/pages/C109150/C109150Config';//제품정보
import { C109160Config } from '../main/content/pages/C109160/C109160Config';//고객사별 단가관리

import { C902003Config } from '../main/content/pages/C902003/C902003Config';

import { C991000Config } from '../main/content/pages/C991000/C991000Config';//사용자 관리
import { C991001Config } from '../main/content/pages/C991001/C991001Config';//사용자 관리 원텍 윈텍
import { C991002Config } from '../main/content/pages/C991002/C991002Config';//사용자 관리 NEW
import { C992000Config } from '../main/content/pages/C992000/C992000Config';
import { C993000Config } from '../main/content/pages/C993000/C993000Config';
import { C994000Config } from '../main/content/pages/C994000/C994000Config';
import { C995000Config } from '../main/content/pages/C995000/C995000Config';
import { C996000Config } from '../main/content/pages/C996000/C996000Config';
import { C997000Config } from '../main/content/pages/C997000/C997000Config'; //환율관리
import { C998000Config } from '../main/content/pages/C998000/C998000Config'; //공정단가관리
import { C998010Config } from '../main/content/pages/C998010/C998010Config'; //GP관리
import { C999000Config } from '../main/content/pages/C999000/C999000Config'; // 공지사항 
import { C999001Config } from '../main/content/pages/C999001/C999001Config'; //  

import { C110010Config } from '../main/content/pages/C110010/C110010Config';
import { C110020Config } from '../main/content/pages/C110020/C110020Config';

import { C109140Config } from '../main/content/pages/C109140/C109140Config';

//7.25 PHW 각 공정 프로세스(흐름도)
import { C111010Config } from '../main/content/pages/C111010/C111010Config';//ERP공정 흐름도
import { C111020Config } from '../main/content/pages/C111020/C111020Config';//POP 흐름도
import { C111030Config } from '../main/content/pages/C111030/C111030Config';//SCM 흐름도
import { C111040Config } from '../main/content/pages/C111040/C111040Config';//불량,반출,리워크 흐름도

export const pagesConfigs = [
    Error404PageConfig,
    Error500PageConfig,

    P100000Config,

    C100010Config,
    C100020Config,
    C100030Config,
    
    /*한국기능공사*/
    C101010Config,
    C101020Config,
    C101023Config,//생산현황
    C101024Config,//성과지표
    C101030Config,
    C101040Config,
    C101050Config,

    C102010Config,
    C102020Config,

    C102031Config,
    C102032Config,
    C102033Config,
    C102034Config,
    C102035Config,
    C102036Config,
    C102037Config,
    C102038Config,
    C102039Config,
    C102041Config,  //안전재고 설정
    C102042Config,  //계획대비실적
    C102043Config,  //생산계획반영(평균출하)
    C102044Config,  //평균출하 생산계획등록
    C102045Config,  //설비별 재공품/금형설정  원테크 윈테크용
        
    C102040Config,
    C102050Config,
    C102060Config,
    C102070Config,
    C102080Config,
    C102090Config,
    C102100Config, // 작업지시 
    C102101Config, // 작업지시 윈테크 원테크 용
    C102110Config,
    C102120Config, // 완제품 생산실적조회
    C102130Config, // 완제품 생산실적등록

    C103010Config,
    C103011Config,
    C103020Config,
    C103030Config,  // 열처리작업일보 
    C103040Config,  // 행잉작업일보 
    C103050Config,  // 사출작업일보
    C103140Config,  // 사출건조투입
    C103141Config,  // 사출배합
    C103060Config,  // 웰딩(용접)작업일보
    C103070Config,  // 조립작업일보
    C103080Config,  // 검사작업일보
    C103081Config,  // 리워크작업일보
    C103082Config,  // 사상, GP작업일보
    C103083Config,  // 표면처리 작업일보
    C103084Config,  // 연마 작업일보
    C103085Config,  // 공정별 작업일보
    C103086Config,  // GP 작업일보
    C103087Config,  // 원테크 작업일보

    C103090Config,
    C103095Config,
    //C103096Config,  // 열처리작업정보2
    C103097Config,  // 설비별 소모품 투입

    C103100Config,
    C103110Config, 
    C103120Config, 
    C103130Config, 
    C103150Config,  // 공정이동정표관리 
    C103155Config,
    C103160Config, 
    C103170Config,
    C103180Config,
    C103190Config,
    C103200Config,
    C103210Config,
    C103300Config,

    C103400Config,
    C103500Config,
    C103600Config,
    C103700Config, //표면처리작업일보
    C103800Config, 
    C103900Config, //MES재고관리

    C104010Config,
    C104020Config,
    C104030Config,
    C104040Config,
    C104050Config,
    C104060Config,
    C104070Config,
    C104080Config,
    C104085Config,
    C104086Config,
    C104087Config,
    C104088Config,
    C104089Config,
    C104090Config,
    C104093Config,
    C104095Config,
    C104100Config,
    C104110Config,
    C104120Config,
    C104130Config,
    C104140Config,
    C104145Config,
    C104150Config,

    C105011Config,
    C105012Config,
    C105013Config, 
    C105021Config,
    C105022Config,
    C105023Config,
    C105031Config,
    C105032Config,
    C105033Config,
    C105034Config,
    C105041Config,
    C105042Config,    
    C105050Config,
    C105060Config,

    C106010Config,
    C106020Config,

    C107010Config,
    C107020Config,
    C107030Config,
    C107040Config,
    C107050Config,
    C107060Config,
    C107070Config,

    C107510Config,
    C107520Config,
    C107540Config,

    C108010Config,
    C108020Config,
    C108030Config,
    C108040Config,
    C108050Config,
    C108060Config,

    C109010Config,
    C109020Config,
    C109025Config,
    C109030Config,
    C109040Config,
    C109050Config,
    C109060Config,
    C109070Config,
    C109080Config,
    C109090Config,
    C109100Config,
    C109120Config,
    C109130Config,
    C109150Config,
    C109160Config,

    C902003Config,

    C991000Config, //사용자 관리 
    C991001Config, //사용자 관리 원텍 윈텍
    C991002Config, //사용자 관리 NEW
    C992000Config,
    C993000Config,
    C994000Config,
    C995000Config,
    C996000Config,
    C997000Config,
    C998000Config,
    C998010Config,
    C999000Config, // 공지사항 
    C999001Config, 

    C109140Config,

    C110010Config,
    C110020Config,

    //7.25 PHW 각 공정 프로세스(흐름도)
    C111010Config, //ERP공정흐름도
    C111020Config, //POP흐름도
    C111030Config, //SCM흐름도
    C111040Config, //불량,반출,리워크 흐름도
]