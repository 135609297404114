export const RESET_AUTOCOMPLATE2 = '[WRAPPER] RESET AUTOCOMPLATE';

export function resetAutoComplate2(isReset2, dateValue) {
    return {
        type: RESET_AUTOCOMPLATE2,
        isReset2,
        dateValue
    };
    
}
