import { query } from '@owp/api';
import { setCommonCodeData } from 'auth/store/actions/common.actions';
import { setUserData } from 'auth/store/actions/user.actions';
import jwtService from 'jwtService';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({ email, password }, nowIP) {
    return dispatch =>
        jwtService
            .signInWithEmailAndPassword(email, password, nowIP)
            .then(async user => {
                dispatch(setUserData(user));

                const result = dispatch({
                    type: LOGIN_SUCCESS,
                });

                try {
                    dispatch(
                        setCommonCodeData(
                            await query({
                                url: '/listIPX_CommonCodeTotalAC',
                            })
                        )
                    );
                } catch (error) {
                    console.error(error);
                }

                window.location.reload();

                return result;
            })
            .catch(error => {
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error,
                });
            });
}
