import { FuseLoadable } from "@fuse";

export const C111010Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/pages/C111010',
            component: FuseLoadable({
                loader: () => import('./C111010')
            })
        }
    ]
};
