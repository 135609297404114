import {
    STORAGE_ACCESS_TOKEN_KEY,
    STORAGE_PERMISSION_KEY,
    STORAGE_SESSION_KEY,
    STORAGE_USER_ID_KEY,
    STORAGE_USER_IP_KEY,
    STORAGE_LOGOUT_REDIRECT_URL,
} from '@owp/constants';
export class OwpStorage {
    static setItem(key, value) {
        localStorage.setItem(key, value);
    }
    static setSessionItem(key, value) {
        sessionStorage.setItem(key, value);
    }
    static getItem(key) {
        const value = localStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (error) {
            return value;
        }
    }
    static getSessionItem(key) {
        const value = sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (error) {
            return value;
        }
    }
    static setSessionKey(session) {
        this.setSessionItem(STORAGE_ACCESS_TOKEN_KEY, session || this.getSessionKey());
    }
    static getSessionKey({ isSessionStorage } = { isSessionStorage: false }) {
        if (isSessionStorage) {
            return this.getSessionItem(STORAGE_ACCESS_TOKEN_KEY);
        }
        return this.getItem(STORAGE_ACCESS_TOKEN_KEY);
    }
    static getSession() {
        return this.getItem(STORAGE_SESSION_KEY);
    }
    static getPermission() {
        return this.getItem(STORAGE_PERMISSION_KEY);
    }
    static getUserId() {
        return this.getItem(STORAGE_USER_ID_KEY);
    }
    static getUserIp() {
        return this.getItem(STORAGE_USER_IP_KEY);
    }

    static setLogoutRedirectUrl(url) {
        OwpStorage.setItem(STORAGE_LOGOUT_REDIRECT_URL, url);
    }

    static getLogoutRedirectUrl() {
        return OwpStorage.getItem(STORAGE_LOGOUT_REDIRECT_URL) || '/login';
    }

    static removeItem(key) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    }
    static clear() {
        localStorage.clear();
        sessionStorage.clear();
    }
}
