import {AppBar, Avatar, Hidden, withStyles,} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const styles = theme => ({
  avatar: {
      "top": "20px",
      "left": "55%",
      "width": "150px",
      "height": "auto",
    position: 'absolute',
    //padding: 5,
    //background: '#d73c2a',
    boxSizing: 'content-box',
    transform: 'translateX(-50%)',
    borderRadius: 0,
    '& > img': {
      borderRadius: 0,
    },
  }
});

function MainNavbarLogo({ classes }) {
    return (
      <AppBar
        style={{ background: '#FFF' }}
        position="static"
        color="primary"
        elevation={0}
        className="user relative flex flex-col items-center justify-center pt-24 pb-16 mb-32 z-0"
      >

          <Avatar
            className={classNames(classes.avatar, 'avatar')}
            alt={process.env.REACT_APP_LOGO_NAME}
                src='assets/images/logos/logo.svg'
            />

      </AppBar>
    );
}

export default withStyles(styles, { withTheme: true })(MainNavbarLogo);
