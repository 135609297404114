import React from 'react';
import TextFieldFormsy from "../@fuse/components/formsy/TextFieldFormsy";
import TextFieldFormsy2 from "../@fuse/components/formsy/TextFieldFormsy2";

const OwpSearchTextField = ({...restProps }) => {

    restProps.style={marginLeft:'8px', marginRight:'8px', marginTop: '7px', width:restProps.width||"140px"}
    restProps.className = "textField";
    

    return (
        <TextFieldFormsy2
            {...restProps}
            type={restProps.type||"text"}
            autoComplete="off"
        />
    );
};

OwpSearchTextField.defaultProps = {
    //className: "textField"
};

export default OwpSearchTextField;
