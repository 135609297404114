import { combineReducers } from 'redux';
import network from './network.reducer';
import page from './page.reducer';
import wrapper from './wrapper.reducer';
import wrapper2 from './wrapper2.reducer';

const owpReducers = combineReducers({
    page,
    network,
    wrapper,
    wrapper2
});

export default owpReducers;
