import {FuseLoadable} from '@fuse';

export const C991000Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C991000',
            component: FuseLoadable({
                loader: () => import('./C991000')
            })
        }
    ]
};
