import throttle from "lodash/throttle";
import store from 'store';
import {showMessage} from 'store/actions';

const _dispatch = throttle(action => {
    console.log('OwpMessage _dispatch !!!!!!');
    store.dispatch(action);
}, 1000);

const OwpMessage = ({message,autoHideDuration,anchorOrigin,variant}) => {

    _dispatch(
        showMessage({
            message     : message,//text or html
            autoHideDuration: autoHideDuration||variant==='warning'? 10000:(variant==='error'? 10000:3000),//ms
            anchorOrigin: anchorOrigin||{
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: variant||'success'//success error info warning null
        })
    );

};


export default OwpMessage;

