import { FuseLoadable } from '@fuse';

export const C104100Config = {
    settings: {
        layout: {
            config: {
                navbar : {
                    display : false,
                    folded  : false,
                    position: 'left'
                },
                toolbar: {
                    display : false,
                    style   : 'fixed',
                    position: 'below'
                },
                footer : {
                    display : false,
                    style   : 'fixed',
                    position: 'below'
                },
            },
        },
    },
    routes: [
        {
            path: '/pages/C104100',
            component: FuseLoadable({
                loader: () => import('./C104100'),
            }),
        },
    ],
};
