import {FuseLoadable} from '@fuse';

export const C103120Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [ 
        {
            path     : '/pages/C103120',
            component: FuseLoadable({
                loader: () => import('./C103120')
            })
        }
    ]
};
 