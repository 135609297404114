import {FuseLoadable} from '@fuse';

export const C104110Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C104110',
            component: FuseLoadable({
                loader: () => import('./C104110')
            })
        }
    ]
};
 