export {
    OWPTGDelEvent,
    OWPTGSelect,
    OWPTGDelete,
    OWPTGAddRow,
    OWPTGSave,
    OWPTGCopyRow,
    OWPTGExport,
    OWPTGExportExcel,
    OWPTGExportPDF,
    OWPTGDataList,
    OWPTGUpdateRow,
    getGridByID,
    OWPTGDataList_Origin, 
    OWPTGSelectRowText,
    OWPTGAddRow2,
    OWPonGridLoad
} from './TGEvent';
