import React from 'react';
import TextFieldFormsy from "../@fuse/components/formsy/TextFieldFormsy";

const OwpSearchTextField = ({...restProps }) => {

    if(restProps.withTable){
        restProps.InputProps={disableUnderline: true};
        restProps.style={backgroundColor:"#f3f3f3",padding:"8px 3px 3px 6px",height:"45px",width:restProps.width||"100%"};

    }else if(restProps.withTable2){
        restProps.style={padding:"8px 10px 10px 6px",height:"45px", width:restProps.width||"80%"}
    }else{
        if(restProps.type != "hidden")
        restProps.className = "textField2";
    }

    return (

        <TextFieldFormsy
            {...restProps}
            type={restProps.type||"text"}
            autoComplete="off"
        />
    );
};

OwpSearchTextField.defaultProps = {
  //  className: "textField2"
};

export default OwpSearchTextField;
