import { Icon, IconButton, InputAdornment, TextField } from '@material-ui/core';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';

let chk = window.navigator.userAgent.toLowerCase().indexOf("chrome") != -1;
let chromeVersion = "";

if(chk === true){
    chromeVersion = get(window.navigator.appVersion.match(/.*Chrome\/([0-9.]+)/)[1].split('.'),0);
}

const DatePicker_JIDOKA = ({ id, inputProps, inputRef, onChange,onClear, ...restProps }) => {
    return (
        <TextField
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            {...restProps}
            inputRef={inputRef}
            type="date"
            inputProps={{
                ...inputProps,
                max: '2999-12-31',
                pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
                id: uniqueId(`${id}-`),
                className: "HKC-TextField"
            }}
            /*eslint-disable-next-line*/
            InputProps={
                chromeVersion >= 83
                    ? {
                        endAdornment: (
                            <InputAdornment
                                className="absolute"
                                position="end"
                                style={{ right: 0 }}
                            >
                                <IconButton
                                    disableRipple
                                    className="p-0"
                                    onClick={() => {
                                        if (!!get(inputRef, 'current')) {
                                            inputRef.current.defaultValue = '';
                                            inputRef.current.value = '';
                                        }

                                        onClear();
                                        onChange(); // 값 변경 이벤트 적용
                                    }}
                                >
                                    <Icon className="text-18">clear</Icon>
                                </IconButton>
                                <IconButton
                                    disableRipple
                                    className="p-0 pointer-events-none"
                                    style={{
                                        marginRight: 2,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Icon className="text-20">{/* event */}</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }
                    : {}
            }
            onChange={evt => onChange(evt.target.value, evt)}
        />
    );
};

DatePicker_JIDOKA.propTypes = {
    id: PropTypes.string.isRequired,
    /**
     * label
     */
    label: PropTypes.string,
    inputProps: PropTypes.object,
    /**
     * 날짜 입력 완료 또는 선택시 실행
     *
     * @param String ex) "2019-04-10"
     * @param Event ex) event object
     */
    onChange: PropTypes.func,
    onClear: PropTypes.func,
};

DatePicker_JIDOKA.defaultProps = {
    id: 'owp-date-picker',
    label: 'YYYY-MM-DD',
    inputProps: {},
    inputRef: {
        current: {},
    },
    onChange: (date, evt) => {},
    onClear: () => {},
};

export default DatePicker_JIDOKA;
