import { FuseLoadable } from '@fuse';


export const C902003Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C902003',
            component: FuseLoadable({
                loader: () => import('./C902003')
            })
        }
    ]
};
