import '@babel/polyfill';
import { FuseAuthorization, FuseLayout, FuseTheme } from '@fuse';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { AutoLogout } from '@owp';
import { setDefaultAxiosConfig } from '@owp/api/axios';
import '@owp/styles/index.css';
import { Auth } from 'auth';
import { create } from 'jss';
import jssExtend from 'jss-extend';
import React from 'react';
import ReactDOM from 'react-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'store';
import 'typeface-muli';
//import './fake-db/fake-db';
import { routes } from './@_configs/owpRoutesConfig';
import history from './history';
import MainNavbarContent from './main/MainNavbarContent';
import MainNavbarHeader from './main/MainNavbarHeader';
import MainToolbar from './main/MainToolbar';
import MobileToolbar from './main/MobileToolbar';
import registerServiceWorker from './registerServiceWorker';
import './styles/index.css';
import {reactLocalStorage} from 'reactjs-localstorage';
import {isMobile} from 'react-device-detect';
import '@wrapper/OwpCommon';
import { query } from '@owp/api';
import { OwpSession } from '@wrapper';

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

setDefaultAxiosConfig();

localStorage.setItem("OWPDESK","true");
try {
    window.pingHost();
}
catch(error) {
    localStorage.setItem("OWPDESK","false");
}

window.addEventListener("unload", (e) =>{
    e.preventDefault();
    const UserId = localStorage.getItem("UserID");

    if(!!UserId && localStorage.getItem("OWPDESK") === "false"){
        query({
            url:`/createUserLog/${OwpSession("USERSEQ")}/${OwpSession("LOGSEG")}/${localStorage.getItem(
                "UserIP"
                )}/창닫기` 
        })
    }
})


class App extends React.Component {

    componentDidMount(){
        document.title = process.env.REACT_APP_DOCUMENT_TITLE;
    }

    allRoutes = routes;

    renderToolBar = (innerWidth) => {

        console.log('renderToolBar.innerWidth',innerWidth);

        if (innerWidth<600||isMobile) {
            reactLocalStorage.set('isMobile', true);
            console.log('renderToolBar = MobileToolbar');
            return <MobileToolbar />
        }else{
            reactLocalStorage.set('isMobile', false);
            console.log('renderToolBar = MainToolbar');
            return <MainToolbar />
        }
    }

    render() {

        console.log('window.outerWidth',window.outerWidth);
        console.log('window.innerWidth',window.innerWidth);

        const innerWidth =  window.innerWidth ;

        return (
            <JssProvider jss={jss} generateClassName={generateClassName}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Auth>
                            <Router history={history}>
                                <FuseAuthorization routes={this.allRoutes}>
                                    <AutoLogout />
                                    <FuseTheme>
                                        <FuseLayout
                                            routes={this.allRoutes}
                                            toolbar={this.renderToolBar(innerWidth)}
                                            navbarHeader={<MainNavbarHeader />}
                                            navbarContent={
                                                <MainNavbarContent />
                                            }
                                            // footer={<MainFooter />}
                                            // rightSidePanel={
                                            //     <React.Fragment>
                                            //         {/* <ChatPanel/> */}
                                            //         <QuickPanel />
                                            //     </React.Fragment>
                                            // }
                                            // contentWrapper={<SettingsPanel />}
                                        />
                                    </FuseTheme>
                                </FuseAuthorization>
                            </Router>
                        </Auth>
                    </PersistGate>
                </Provider>
            </JssProvider>
        );
    }
}
ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
