import classNames from 'classnames';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isEqual from 'date-fns/is_equal';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useRef, useState ,useEffect} from 'react';
import DatePicker from './DatePicker';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import * as Actions from 'store/actions/owp';
import moment from "moment";
import Button from "@material-ui/core/Button/index";

const START = 0;
const END = 1;
const YYYYMMDD_LENGTH = 8;
const LABEL_KEYS = ['start', 'end'];

function makeDateValue(str = '', char = '') {
    if (!!char && str.length <= YYYYMMDD_LENGTH) {
        return str.concat(char);
    }

    return str.slice(0, YYYYMMDD_LENGTH);
}

function _debounce(func = noop, wait = 100) {
    debounce(() => func(), wait)();
}

const DatePickerMulti = ({
    id,
    fullWidth,
    labels,
    onChange,
    startDateProps,
    endDateProps,
    isReset,
    isReset2,
    dateValue,
    resetAutoComplate,
    resetAutoComplate2,
    startDate,
    endDate,
    ...restProps
}) => {

    const dateAttrNames = [`${id}_STARTDATE`, `${id}_ENDDATE`];

    const [helperTextData, setHelperText] = useState({});
    const [validationInputData, setValidationInput] = useState({
        flag: true,
        value: '',
    });
    const datesInstance = [useRef(null), useRef(null)];

    const resetValidationInputData = () => {
        
        setValidationInput({
            flag: true,
            value: '',
        });
    };

    const today = moment().format('YYYY-MM-DD');
    const today2 = moment().format('YYYY-MM-DD');
    const weekDate = moment().add(+7,'d').format('YYYY-MM-DD')
    const weekDate2 = moment().subtract(14,'d').format('YYYY-MM-DD')
    const weekDate3 = moment().subtract(7,'d').format('YYYY-MM-DD')
    const weekDate4 = moment().add(+14,'d').format('YYYY-MM-DD')
    const month = moment().add(+1,'M').format('YYYY-MM-DD')

    let weekFirstDate = moment().startOf('week').add(1,'d').format('YYYY-MM-DD')
    let weekLastDate = moment().endOf('week').add(1,'d').format('YYYY-MM-DD')

    let preWeekFirstDate = moment().startOf('week').subtract(6,'d').format('YYYY-MM-DD')
    let preWeekLastDate = moment().endOf('week').subtract(6,'d').format('YYYY-MM-DD')

    let monthFirstDate = moment().startOf('month').format('YYYY-MM-DD')
    let monthLastDate = moment().endOf('month').format('YYYY-MM-DD')

    let preMonthFirstDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    let preMonthLastDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

    if(isReset){
        if(startDate==='today' || startDate==='today2' || startDate === 'today_107060' || startDate==='weekDate' ||startDate==='weekDate2' || startDate==='weekDate3' || startDate==='weekDate4'|| startDate==='monthFirstDate') {
            if(startDate==='today')
                datesInstance[START].current.value = today;
            if(startDate==='today2')
                datesInstance[START].current.value = today2;
            if(startDate==='today_107060')
                datesInstance[START].current.value = today;
            if(startDate==='weekDate2')
                datesInstance[START].current.value = weekDate2;
            if(startDate==='weekDate3')
                datesInstance[START].current.value = weekDate3;
            if(startDate==='weekDate4')
                datesInstance[START].current.value = weekDate4;
            if( endDate==='today')
                datesInstance[END].current.value=today
            if( endDate==='today2')
                datesInstance[END].current.value=today2
            if( endDate==='today_107060')
                datesInstance[END].current.value=today
            if( endDate==='weekDate')
                datesInstance[END].current.value=weekDate;
            if( endDate==='weekDate2')
                datesInstance[END].current.value=weekDate2;
            if( endDate==='weekDate4')
                datesInstance[END].current.value=weekDate4;
            if(startDate==='monthFirstDate')
                datesInstance[START].current.value = monthFirstDate;
            if( endDate==='monthLastDate')
                datesInstance[END].current.value=monthLastDate;
        }
        else{
            datesInstance[START].current.value="";
            datesInstance[END].current.value="";
        }
        
        resetAutoComplate(false);
    }

    function dateValueSet() {
        return {
            [dateAttrNames[START]]: datesInstance[START].current.value,
            [dateAttrNames[END]]: datesInstance[END].current.value,
        };
    }

    if(isReset2){
        if(dateValue === "Today"){
            datesInstance[START].current.value = today;
            datesInstance[END].current.value = today;
        }else if(dateValue === "Week"){
            datesInstance[START].current.value = weekFirstDate;
            datesInstance[END].current.value = weekLastDate;
        }else if(dateValue === "Month"){
            datesInstance[START].current.value = monthFirstDate;
            datesInstance[END].current.value = monthLastDate;
        }else if(dateValue === "PreWeek"){
            datesInstance[START].current.value = preWeekFirstDate;
            datesInstance[END].current.value = preWeekLastDate;
        }else if(dateValue === "PreMonth"){
            datesInstance[START].current.value = preMonthFirstDate;
            datesInstance[END].current.value = preMonthLastDate;
        }
    
        onChange(dateValueSet());
        
        resetAutoComplate2(false);
    }

    useEffect(() => {
        if((startDate==='today' || startDate==='today2' || startDate === 'today_107060' || startDate==='weekDate' ||startDate==='weekDate2' || startDate==='weekDate3' || startDate==='weekDate4' || startDate==='monthFirstDate') && (endDate==='today' || endDate==='today2' || endDate==='today_107060' || endDate==='weekDate' || endDate==='weekDate2' || endDate==='weekDate3' || endDate==='weekDate4'|| endDate==='monthLastDate')) {
            setDate();
        } 
    }, []);

    const setDate = () => {
        const today = moment().format('YYYY-MM-DD');
        let today_107060_start = moment().format('YYYY-MM-DD');
        let today_107060_end = moment().format('YYYY-MM-DD');

        if(localStorage.getItem("107060_OUT_DATE") !== null){
            today_107060_start = localStorage.getItem("107060_OUT_DATE").substr(0, 10)
            today_107060_end = localStorage.getItem("107060_OUT_DATE").substr(11,20)
        }

        if(startDate==='today') 
            datesInstance[START].current.value = today;

        if(startDate==='today2') 
            datesInstance[START].current.value = today2;

        if(startDate==='today_107060') 
            datesInstance[START].current.value = today_107060_start;

        if(startDate==='weekDate') 
            datesInstance[START].current.value = weekDate;

        if(startDate==='weekDate2') 
            datesInstance[START].current.value = weekDate2;

        if(startDate==='weekDate3') 
            datesInstance[START].current.value = weekDate3;

        if(startDate==='weekDate4') 
            datesInstance[START].current.value = weekDate4;
        
        if(startDate==='monthFirstDate') 
            datesInstance[START].current.value = monthFirstDate;

        if(endDate==='today')
            datesInstance[END].current.value=today;

        if(endDate==='today2')
            datesInstance[END].current.value=today2;

        if(endDate==='today_107060')
            datesInstance[END].current.value=today_107060_end;

        if(endDate==='weekDate')
            datesInstance[END].current.value=weekDate;

        if(endDate==='weekDate2')
            datesInstance[END].current.value=weekDate2;

        if(endDate==='weekDate3')
            datesInstance[END].current.value=weekDate3;
            
        if(endDate==='weekDate4')
            datesInstance[END].current.value=weekDate4;
        
        if(endDate==='monthLastDate')
            datesInstance[END].current.value=monthLastDate;
        
        onChange(getDates());
    }

    const getDates = () => {
        return {
            [dateAttrNames[START]]: datesInstance[START].current.value,
            [dateAttrNames[END]]: datesInstance[END].current.value,
        };
    };

    const moveNextItem = isStart => {
        if (validationInputData.flag) {
            if (isStart) {
                _debounce(() => datesInstance[END].current.focus());
                return;
            }

            if (!isStart && !datesInstance[START].current.value) {
                _debounce(() => datesInstance[START].current.focus());
                return;
            }
        }
    };

    const makeDateProps = ({ inputProps, ...restProps } = {}) => {

        return {
            ...restProps,
            inputProps: {
                ...inputProps,
                onKeyDown: handleOnKeyDown,
                onBlur: resetValidationInputData,
            },
        };
    };

    const handleOnKeyDown = evt => {
        if (!!/^\d+$/.test(evt.key)) {
            const _validationInputData = validationInputData;
            _validationInputData.value = makeDateValue(
                validationInputData.value,
                evt.key
            );

            if (_validationInputData.value.length < YYYYMMDD_LENGTH) {
                _validationInputData.flag = false;
                setValidationInput(_validationInputData);
                return;
            }

            resetValidationInputData();
        }
    };

    const handleOnChange = (isStart, currAttrName) => nextValue => {

        onChange(getDates());

        if (!!nextValue) {
            if (
                (isStart &&
                    !!datesInstance[END].current.value &&
                    isAfter(nextValue, datesInstance[END].current.value)) ||
                (!isStart &&
                    !!datesInstance[START].current.value &&
                    isBefore(nextValue, datesInstance[START].current.value))
            ) {
                // error handling
                setHelperText({
                    ...helperTextData,
                    [currAttrName]: isStart
                        ? `${labels.start} 은 ${labels.end} 보다 클수 없습니다.`
                        : `${labels.end} 은 ${labels.start} 보다 커야 합니다.`,
                });
            // } else if (
            //     isEqual(
            //         datesInstance[START].current.value,
            //         datesInstance[END].current.value
            //     )
            // ) {
            //     // error handling
            //     setHelperText({
            //         ...helperTextData,
            //         [currAttrName]: `${
            //             labels[LABEL_KEYS[isStart ? START : END]]
            //         } 이 ${
            //             labels[LABEL_KEYS[isStart ? END : START]]
            //         } 과 같습니다.`,
            //     });
            } else {
                //success
                //moveNextItem(isStart);
                setHelperText({});
            }
        }
    };

    return (
        <div className="flex">
            {dateAttrNames.map((currAttrName, index) => {
                const isStart = index === 0;
                const helperText = helperTextData[currAttrName];
                return (
                    <DatePicker
                        className={classNames({ 'mr-20': isStart })}
                        {...makeDateProps(
                            isStart ? startDateProps : endDateProps
                        )}
                        key={`${id}-${index}`}
                        id={id}
                        type="date"
                        error={!!helperText}
                        helperText={helperText}
                        fullWidth={fullWidth}
                        label={labels[LABEL_KEYS[isStart ? START : END]]}
                        inputRef={datesInstance[isStart ? START : END]}
                        onChange={handleOnChange(isStart, currAttrName)}
                        inputProps={{
                            className: "HKC-TextField"
                        }}
                    />
                );
            })}
        </div>
    );
};

DatePickerMulti.propTypes = {
    /**
     * 날짜 선택 후 onChange param (object) 전달시 `STARTDATE`, `ENDDATE` _prefix_ 로 사용
     */
    id: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    /**
     * label 을 설정한다.
     * @param Object { start: 'start', end: 'end' }
     */
    labels: PropTypes.object,
    /**
     * 시작일 component props
     */
    startDateProps: PropTypes.object,
    /**
     * 종료일 component props
     */
    endDateProps: PropTypes.object,
    /**
     * 시작/종료 날짜 입력 완료 또는 선택시 실행
     *
     * @param Object ex) _id_ 가 `DEALDATE` 라면 -> { `DEALDATE_STARTDATE`: '...', `DEALDATE_ENDDATE`: '...'}
     */
    onChange: PropTypes.func,
};

DatePickerMulti.defaultProps = {
    id: 'OWP_DATES',
    fullWidth: false,
    labels: {
        start: '시작일',
        end: '종료일',
    },
    startDateProps: {value : '2019-08-01'},
    endDateProps: {value : '2019-08-30'},
    onChange: dates => {},
};

function mapStateToProps({ owp }) {

    return {
        isReset: owp.wrapper.isReset,
        isReset2: owp.wrapper2.isReset2,
        dateValue: owp.wrapper2.dateValue,
    };
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        resetAutoComplate: Actions.resetAutoComplate,
        resetAutoComplate2: Actions.resetAutoComplate2
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerMulti);
