import * as Actions from '../../actions/owp';

const initialState = {
    isReset2: false,
    dateValue: 0
};

const wrapper = function(state = initialState, action) {
    switch (action.type) {
        case Actions.RESET_AUTOCOMPLATE2:
            return {
                ...state,
                isReset2: action.isReset2,
                dateValue: action.dateValue
            };

        default:
            return state;
    }
};

export default wrapper;
