import { FuseUtils } from '@fuse/index';
import { LoginConfig } from '../main/content/login/LoginConfig';
import { LogoutConfig } from '../main/content/logout/LogoutConfig';
import { pagesConfigs } from './pagesConfigs';
import { RegisterConfig } from '../main/content/register/RegisterConfig';
import { examplesConfig } from '../main/content/examples/examplesConfig';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { OwpSession } from '@wrapper';

const routeConfigs = [
  ...pagesConfigs,
  LoginConfig,
  RegisterConfig,
  examplesConfig,
  LogoutConfig,
];

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={"/pages/" + OwpSession("MES_STARTPAGE")}/>,
  },
  {
    component: () => <Redirect to="/login"/>,
  },
];
