 const OwpSession = function (key) {

    let session = window.localStorage.getItem('session');
    if(session){
        session = JSON.parse(window.localStorage.getItem('session'));
        return ""+session[key];
    }else{
        return null;
    }

};

export default OwpSession;
