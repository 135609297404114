export const STORAGE_AUTO_LOGOUT_TIME_KEY = 'OWP_SessionExpireMinute';
export const STORAGE_ACCESS_TOKEN_KEY = 'jwt_access_token';
export const STORAGE_FAILED_LOGIN_KEY = 'loginfail';
export const STORAGE_SESSION_KEY = 'session';
export const STORAGE_USER_ID_KEY = 'UserID';
export const STORAGE_USER_IP_KEY = 'UserIP';
export const STORAGE_PERMISSION_KEY = 'PERMIT';
export const STORAGE_LOGIN_STAT = 'loginStat';
export const STORAGE_CLOSE_STAT = 'closeStat';
export const STORAGE_LOGOUT_REDIRECT_URL = 'OWP_LOGOUT_REDIRECT_URL';
